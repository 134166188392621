@import "../common/colors.less";

.payment-plans__header {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.payment-plans__header-text {
    text-align: center;
    color: @black;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.payment-plans__content {
    border-radius: 20px;
    background: #FFF;
    margin-top: 30px;
    padding: 30px 0px;
}

.payment-plans__confirm-content {
    display: flex;
    flex-direction: column;
    max-width: 690px;
    height: max-content;
    margin-top: 66px;
}

.payment-plans__confirmed-content {
    margin: 0 auto;
    padding: 30px;
    max-width: 435px;
    border-radius: 20px;
    background: @white;
}

.payment-plans__confirm-card {
    width: 458px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.payment-plans__confirm-card-title {
    color: @brand;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
}

.payment-plans__confirmed-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.payment-plans__back-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.payment-plans__content-heading {
    color: @grey7;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
}

.payment-plans__text {
    color: @grey7;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.payment-plans__confirmed-text {
    color: @grey7;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; 
}

.payment-plans__options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}

.payment-plans__row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.payment-plans__card {
    position: relative;
    width: 255px;
    height: 430px;
    padding: 30px 20px !important;
    background-color: @white;
    border-radius: 26.34px;
    // margin-bottom: 30px;
    display: flex;
    border: 2px solid @white;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    border: 2px solid #EEE;

    &.selected {
        border: 2px solid @brand;
        background: @brandLightFivePercent;
    }
}

.payment-plans__option-title {
    color: @black;
    text-align: left;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.payment-plans__option-subtitle {
    color: @grey8;
    text-align: left;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.payment-plans__option-description {
    color: @grey8;
    text-align: start;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 17px;
    display: flex;
    align-items: center;
}

.payment-plans__option-vat{
    font-size: 12px;
    font-weight: 400;
    color: #ADADAD;
    text-align: center;
    margin-bottom: 20px; 
    margin-top: 30px
}

.payment-select-plan-button {
    width: 100%;
    height: 50px;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: @brand;
    color: @white;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    &:not(:disabled):hover {
        background: @brandDark !important;
    }
}

.payment-select-plan-button img {
    margin-left: 5px;
}

.payment-plans__most-popular {
    position: absolute;
    top: -10px;
    right: 4px;
}

.payment-plans__your-refund {
    display: flex;
    width: 260px;
    padding: 20px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: @white;
    border: 1px solid @secondaryDark;
}

.payment-plans__your-refund-text {
    color: @secondaryDark;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 13px;
    text-transform: uppercase;
}

.payment-plans__your-refund-amount {
    margin-top: 12px;
    color: @secondaryDark;
    text-align: center;
    font-family: "Open Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.payment-plans__confirm-button {
    margin-top: 30px;
    width: 330px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: @brand;
    background-color: @white;
    border: 2px solid @brand;
    border-radius: 10px;
    font-family: "Poppins";
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.payment-plans__dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 175px;

    .payment-plans__dot {
        margin: 0 4px;
        width: 8px;
        height: 8px;
        border-radius: 10px;
        border: 2px solid #2EBB49;

        &.active {
            width: 16px;
            border: none;
            background: #2EBB49;
        }
    }
}

.payment-plans__confirm-calculation-row {
    display: flex;
    justify-content: space-between; 
    align-items: center;
}

.payment-plans__confirmed-calculation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom: 100%;
}

.basic-modal__content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-plans__option-refund-container {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.payment-plans__option-refund {
    margin-left: 12px;
    color: @black;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.payment-plans__o4a-logo {
    position: absolute;
    top: 169px;
    left: 91px;
}

.payment-plans__receive_prompt {
    color: @grey6;
    text-align: left;
    font-family: "Poppins";
    font-size: 12px;
    font-style: italic;
    font-weight: lighter;
    line-height: normal;
    margin-bottom: 3px;
    margin-top: 12px;
    position: static;
}

@media (max-width: 1109px) {
    .payment-plans__header {
        margin-top: 0px;
        max-width: 375px;
        flex-direction: column;
    }

    .payment-plans__your-refund {
        margin: 0 auto;
    }

    .payment-plans__options {
        margin-top: 0px;
        width: 375px;
    }

    .payment-plans__row {
        width: 375px;
    }

    .payment-plans__card {
        padding: 20px !important;
        width: 320px;
        height: 350px;
        margin: 10px auto;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    }

    .payment-plans__content {
        width: 100%;
        padding: 0;
        margin-top: 0;
    }

    .payment-plans__confirmed-content {
        margin: 0 auto;
        padding: 20px;
        width: 375px;
    }

    .payment-plans__confirm-content {
        margin-top: 0px;
    }

    .payment-plans__back-header {
        margin-top: -6px;
    }

    .payment-plans__header-text {
        height: 40px;
    }

    .payment-plans__option-refund-container {
        margin-top: 10px;
    }
    .payment-plans__receive_prompt {
        color: @grey6;
        text-align: left;
        font-family: "Poppins";
        font-size: 15px;
        font-style: italic;
        font-weight: 1;
        line-height: normal;
        margin-bottom: 3px;
        margin-top: 17px;
        position: static;
    }
}
@primary-color: #2ebb49;