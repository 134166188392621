// vars
@footer: #222222;
@black: #000000;
@grey13: #CDCDCD;
@grey12: #BBB;
@grey11: #4A4A4A;
@grey10: #474747;
@grey9: #F8F8F8;
@grey8: #444444;
@grey7: #555555;
@grey6: #777777;
@grey5: #a3a3a3;
@grey4: #d7d7d7;
@grey3: #d0d5db;
@grey2: #e7ebef;
@grey1: #F7F7F7;
@grey0: #F3F3F3;
@white: #ffffff;
@blue: #1253FA;
@lightBlue: #007DEE;
@brandDark: #2c9340;
@brand: #2ebb49;
@brandSuperLight: #f5fcf6;
@brandFivePercent: rgba(46, 187, 73, 0.5);
@brandLight: #4ec264;
@brandLightTenPercent: #4ec2631a;
@brandLightFivePercent: #eaf8ed;
@red: #ec0000;
@primary: @brand;
@secondary: #7f43ff;
@secondaryLight: #EFE8FF;
@secondaryDark: #4C0A82;
@selectedHover: #DCD4EF;
@textHover: #707070;

// styles colors
.primary-color {
    color: @brand !important;
}
.secondary-color {
    color: @secondary !important;
}
.brand-dark-color {
    color: @brandDark;
}
.brand-light-color {
    color: @brandLight;
}
.brand-5Percent-color {
    color: @brandFivePercent;
}
.grey-10-color {
    color: @grey10;
}
.grey-7-color {
    color: @grey7;
}
.grey-6-color {
    color: @grey6;
}
.grey-5-color {
    color: @grey5;
}
.grey-4-color {
    color: @grey4;
}
.grey-3-color {
    color: @grey3;
}
.grey-2-color {
    color: @grey2;
}
.grey-1-color {
    color: @grey1;
}
.black-color {
    color: @black;
}
.red-color {
    color: @red;
}
.white-color {
    color: @white;
}
.blue-color {
    color: @blue;
}
// background colors
.bg-secondary {
    background-color: @secondary;
}
.bg-secondary-light {
    background-color: @secondaryLight;
}
