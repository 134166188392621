@import "./common/colors.less";

.float-label {
    position: relative;
}

.two-line-placeholder {
    line-height: 14px !important;
    width: 310px;
    font-size: 14px !important;
    text-align: start !important;
}

.two-line-label {
    width: 318px;
    top: -17px !important;
}

.label {
    color: @grey5;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 11px;
    transition: 0.2s ease all;
}

.input-field {
    height: 48px;
    line-height: 48px;
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border-radius: 5px;
}

.input-field:focus + .as-label {
    color: @brand;
}

.input-field:focus,
.input-field:hover {
    border-color: @brand !important;
    outline: none;
    box-shadow: 0 0 3px @grey5;
}

.as-placeholder {
    color: @grey5;
}

.as-label {
    color: @grey5;
    font-size: 11px !important;
    line-height: normal;
    top: -8px;
    background: @white;
    padding: 0 4px;
    margin-left: -4px;
}

.text-error {
    color: @grey6;
}

.phone-input-country-code {
    width: 120px !important;
    height: 48px;
}

.phone-input-country-code .ant-select-selector {
    height: 48px !important;
    align-items: center;
    color: @black;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.phone-input-country-code .ant-select-selector .ant-select-selection-search{
    height: 48px !important;
    align-items: center;
    color: @black;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.phone-input-country-code-item {
    height: 48px !important;
    color: @black;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.phone-input-country-code-select-item {
    width: 79px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ant-picker-body {
    padding: 0 !important;
}

.suffix-icon {
    height: 24px;
    position: absolute;
    right: 10px;
    top: 11px
}
.status-icon {
    height: 24px;
    position: absolute;
    right: 15px;
    top: 5px
}

.status-icon-clickable {
    height: 24px;
    position: absolute;
    right: 15px;
    top: 5px;
    border-radius: 15px;
    animation: growShrink 2s infinite ease-in-out;
    @keyframes growShrink {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
    &:hover {
        cursor: pointer;
    }
}

.static-placeholder {
    position: absolute;
    right: 28px;
    top: 12px;
    color: @grey4;
    text-align: right;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.form-input-wrapper {
    display: flex;
}

.uk-float-label {
    position: relative;
}

.uk-float-date-picker__calendar {
    font-family: Poppins ;
    font-size: 12px;
    .react-datepicker__header {
        border: none;
        background-color: white;
    }
}

.uk-float-date-picker__calendar-input {
    border: none;
    border-bottom: 1px solid #E5E5E5;
    width: 365px;
    height: 36px;
    font-size: 16px;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: #000;
    outline: none;

    &::placeholder {
        color: #ABABAB;
    }
}

.uk-float-label .ant-input-outlined{
    border-style: none !important;
    border-bottom-style: solid !important;
    font-family: Poppins, sans-serif;
}

.uk-float-label .ant-picker-outlined{
    border-style: none !important;
    border-bottom-style: solid !important;
}

.uk-label {
    color: @grey5;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    position: absolute;
    pointer-events: none;
    left: 0px;
    top: 5px;
    transition: 0.2s ease all;
}

.uk-input-field {
    height: 36px;
    line-height:36px;
    padding: 0px;
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #E5E5E5;
    box-shadow: none !important;
}

.uk-as-label {
    color: @grey5;
    font-size: 11px !important;
    line-height: normal;
    top: -11px;
}

.uk-phone-input-country-code {
    width: 120px !important;
    height: 48px;

}

.uk-phone-input-country-code .ant-select-selector {
    margin-top: 6px;
    height: 36px !important;
    align-items: center;
    color: @black;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #E5E5E5 !important;
    box-shadow: none !important;
}

@media (max-width: 1110px) {
    .label {
        font-size: 14px;
        text-align: left;
    }

    .static-placeholder {
        right: 12px;
    }
}

@primary-color: #2ebb49;