@import "../common/colors.less";

.footer-tabs-wrapper {
    width: 100%;
    background-color: #fff;
    z-index: 6;
    position: fixed;
    bottom: 0;
    box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.10);
}

.footer-tabs {
    display: flex;
    justify-content: center;
}

.footer-tabs .ant-menu-horizontal {
    border-bottom: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.footer-tabs .ant-menu-item-selected::after {
    border-bottom: none !important;
}

.footer-tabs .ant-menu-item {
    flex: 1;
    max-width: 80px; // set a reasonable max width for each tab
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 6px !important; // spacing between buttons
    padding: 8px 4px !important;
    border-radius: 50px;
    transition: background-color 0.2s ease;
}

.footer-tabs .ant-menu-item:hover {
    background-color: #f9f9f9;
}

.footer-tabs .ant-menu-item img {
    width: 24px;
    height: auto;
}

.footer-tabs .ant-menu-item span {
    display: block;
    margin: 0 !important;
    color: @grey7;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.footer-tabs .ant-menu-item-selected span {
    color: @secondary;
}

@primary-color: #2ebb49;