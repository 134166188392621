.transactionsFilters__tag {
    border-radius: 32px;
}

.transactionsFilters__mobile-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 5px 0px;
    box-sizing: border-box;
    overflow-x: auto;
    gap: 7px;
    position: relative;
    scroll-behavior: smooth;
    max-width: 100vw;
}

.transactionsFilters__mobile-menu::-webkit-scrollbar {
    display: none !important;
}

.transactionsFilters__mobile-menu {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

@media (max-width: 576px) {
    .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
        visibility: visible !important;
      }
      
      .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
        visibility: visible !important;
      }
      
      .ant-picker-panels > *:last-child {
        display: none !important;
      }
      
      .ant-picker-panel-container, .ant-picker-footer {
        width: 290px !important;
      }
      
      .ant-picker-footer-extra > div {
        flex-wrap: wrap !important; 
      }
  }



@primary-color: #2ebb49;