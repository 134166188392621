@import "./common/colors.less";

.category-files {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.drag-component-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.drag-component-content {
    width: 160px;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button-component {
    width: 218px;
    height: 44px;
    .ant-upload-drag {
        border: none;
        border-radius: 10px;
        background: #EFF8FE !important;
    }
}

.or-component {
    color: #A3A3A3;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 40px;
    display: flex;
    align-items: center;
    line-height: normal;
}

.button-component-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.drag-component-text {
    color: @blue;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
}

.file-component-download {
    cursor: pointer;
    position: absolute;
    top: 3px; 
    right: 10px
}

.file-component-delete {
    cursor: pointer;
    position: absolute;
    bottom: 3px; 
    right: 10px
}

@media (max-width: 1109px) {
    .drag-component-content {
        width: 335px;
    }
   
    .category-files {
        gap: 8px;
        justify-content: center;
    }
    .category-files-modal {
        .ant-modal-content {
            padding: 0px !important;
            .ant-modal-body {
                padding: 0px;
            }
        }
    }
}
@primary-color: #2ebb49;