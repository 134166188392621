@import "./common/colors.less";

.uk-footer-block {
    background-color: #0C0B0A;
}

.uk-footer-header {
    color: white;
    font-size: 27px;
    font-weight: 700;
}

.uk-footer-link {
    color: white;
    font-size: 17px;
    font-weight: 400;
}
.uk-footer-link:hover {
    color: @brand;
}

@media (max-width: 1109px) {
    .uk-footer-block {
        padding-bottom: 80px;
    }
}