.navigation-button {
  margin-left: 8px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  shape-rendering: geometricPrecision;
  border: 1px solid white;
  border-radius: 100%;
}

.navigation-button-bordered {
  border: 1px solid white;
  border-radius: 100%;
}

.navigation-icon {
  font-size: 18px;
  color: white
}

.page-header {
  /*height: 48px;*/
  width: 100%;
  box-shadow: 0 3px 24px rgba(49,58,69,0.07);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  z-index: 5;
  background-color: white;
}

.icon() {
  transition: 0.2s ease-in-out;
}
