@import "../common/colors.less";
@import "./shared.less";

.new-questionnaire__desktop-form-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.new-questionnaire__desktop-header {
    box-sizing: border-box;
    width: 100%;
    max-width: 1440px;
    height: 96px;
    min-height: 96px;
    padding: 0 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.new-questionnaire__client {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 16px;
    cursor: pointer;
    border-radius: 10px;
    background: #FAF7FF;
    width: 229px;
    padding: var(--spacing-xs, 6px) 20px;
    gap: 10px;
}

.new-questionnaire__desktop-header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.new-questionnaire__refund {
    display: inline-flex;
    cursor: pointer;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 10px;
    border: 1px solid #F3F3F3;
    margin-top: 16px;
    margin-bottom: 8px;
    position: relative;
}

.new-questionnaire__bank-accounts {
    display: inline-flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.new-questionnaire__connected-accounts-number {
    color: #000;
    text-align: right;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.new-questionnaire__client-name {
    color: #121212;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.new-questionnaire__client-email {
    color: #707070;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;

}

.new-questionnaire__profile-link {
    text-decoration: none;
    display: flex;
    transition: transform 0.3s ease-out;
    &:hover {
        transform: scale(1.1);
    }
}

.custom-tooltip-wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border: 1px solid @grey2;
    border-radius: 10px;
}

.custom-prompt-wrapper {
    position: relative;
    display: inline-block;
}

.custom-prompt-content {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -13%);
    z-index: 100;
    border-radius: 10px;
    border: 1px solid #F3F3F3;
    background: #fff;
    box-shadow: 0px -4px 10px 0px rgba(100, 133, 249, 0.10), 0px 3px 10px 0px rgba(100, 133, 249, 0.10);
    padding: 20px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 420px;
    border-radius: 20px 20px 20px 20px;
    padding: 30px;
}

.custom-tooltip-content {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    border-radius: 10px;
    border: 1px solid #F3F3F3;
    background: #fff;
    box-shadow: 0px -4px 10px 0px rgba(100, 133, 249, 0.10), 0px 3px 10px 0px rgba(100, 133, 249, 0.10);
    display: inline-flex;
    padding: 20px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.new-questionnaire__refund-tooltip {
    width: 275px;
}

.new-questionnaire__prompt-text {
    color: #121212;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
.new-questionnaire__prompt-title {
    color: #7F43FF;
    text-align: center;
    padding: 15px;
    font-feature-settings: 'dlig' on;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.07px;
}

.new-questionnaire__connection-title{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.005em;
    text-align: center;
    margin-bottom: 15px;;
}

.new-questionnaire__connection-text{
    color: #707070;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.new-questionnaire__warning-text {
    color: #F44336;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.new-questionnaire__refund-details {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    border-radius: 6px;
    border: 1px solid #F3F3F3;
}

.new-questionnaire__refund-label {
    color: #707070;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.new-questionnaire__refund-value {
    color: #121212;
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.new-questionnaire__total-refund-text {
    color: @grey10;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.new-questionnaire__total-refund-value {
    color: #121212;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.new-questionnaire__bank-accounts-tooltip {
    display: flex;
    flex-direction: column;
    width: 335px;
    max-width: 335px;
    height: auto;
}

.new-questionnaire__bank-accounts-tooltip__button {
    cursor: pointer;
    font-family: Poppins;
    display: flex;
    height: 62px;
    padding: 0px 86px 0px 87px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #7F43FF;
    color: #fff;
}

.new-questionnaire__bank-accounts-tooltip__button:hover {
    background: #712fff;
    box-shadow: 0px 0px 7px #7f43ff72;
}

.new-questionnaire__bank-accounts-label {
    color: #121212;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.new-questionnaire__bank-accounts-text {
    color: @grey10;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.new-questionnaire__bank-accounts-list {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    min-width: 345px;
}


.new-questionnaire__add-bank-account-button {
    font-family: Poppins;
    display: flex;
    height: 62px;
    width: 100%;
    padding: 0px 86px 0px 87px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #7F43FF;
    color: #fff;
    margin-top: 30px;
}

.new-questionnaire__divider-container {
    width: 100%;
    height: 6px;
    background-color: #F3F3F3;
    position: relative;
    border-radius: 10px;
}

.new-questionnaire__progress-bar {
    height: 6px;
    background-color: @brand;
    position: absolute;
    left: 0;
    border-radius: 10px;
    transition: width 1s ease;
}

.new-questionnaire__desktop-form-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.new-questionnaire__desktop-menu {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-width: 162px;
    width: 162px;
    overflow-y: scroll!important;
    background: #FFF;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    padding: 20px;
    border-right: solid 2px @grey0;
    z-index: 5;
}

.new-questionnaire__desktop-menu::-webkit-scrollbar {
    width: 6px; /* Scrollbar width */
    height: 5px;
}

.new-questionnaire__desktop-menu::-webkit-scrollbar-thumb {
    background-color: @grey0; /* Scrollbar thumb color */
    border-radius: 4px;
}

.new-questionnaire__desktop-form {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
}

.new-questionnaire__desktop-form-left {
    width: 120px;
    height: 100%;
}

.new-questionnaire__desktop-form-right {
    width: 120px;
    height: 100%;
}

.new-questionnaire__desktop-form-back {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
}

.new-questionnaire__desktop-form-back-text {
    color: #555;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.08px;
    margin-left: 4px;
}

.new-questionnaire__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    border-radius: 4px;
}

.new-questionnaire__step--undone, .new-questionnaire__step--inProgress {
    color: #707070;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
}

.new-questionnaire__step--active {
    color: #7F43FF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
}

.new-questionnaire__step--done {
    color: #ABABAB;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
}

.new-questionnaire__step--disallowed {
    color: @grey3;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    cursor: not-allowed;
}

.new-questionnaire__form-title {
    color: #000;
    text-align: left;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.1px;
    margin: auto;
    max-width: 340px;
}

.new-questionnaire__form-description {
    margin-top: 12px;
    color: #707070;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    max-width: 335px;
    width: 100%;
    margin: 10px auto;
}

.new-questionnaire__question-title {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.2px;
}

.new-questionnaire__question-text {
    margin-top: 16px;
    color: #444;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    width: 335px;
    text-align: left;
}

.new-questionnaire__info-text {
    margin-top: 10px;
    color: #717171;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    max-width: 335px;
    font-size: 14px;
}

.new-questionnaire__question {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.new-questionnaire__question-input-wrapper {
    width: 100%;
    max-width: 335px;
    display: flex;
    margin: 40px auto 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.new-questionnaire__amount-question-input-prefix {
    color: #C4C4C4;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.new-questionnaire__amount-question-input {
    border: none;
    box-shadow: none !important;
    outline: none;
    text-align: center;
    padding: 0px;
    margin-left: 7px;
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: auto;
    flex: 0 1 auto;
    min-width: 20px;
    caret-color: @brand;
}

.new-questionnaire__amount-question-input::-webkit-outer-spin-button,
.new-questionnaire__amount-question-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.new-questionnaire__edit-expense-input {
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    font-family: Poppins;
    text-align: right;
    border: none;
    box-shadow: none !important;
    outline: none;
    padding: 0px;
    color: @black;
    font-family: Poppins;
    font-style: normal;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    caret-color: @brand;
}

.new-questionnaire__error-message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    margin: 12px auto;
}

.new-questionnaire__info-message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    max-width: 300px;
    margin: 12px auto;
}

.new-questionnaire__error-message-text {
    color: #F44336;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.new-questionnaire__info-message-text {
    color: #C4C4C4;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.new-questionnaire__input {
    height: 48px;
}

.new-questionnaire__input-text{
    color: @black !important;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.new-questionnaire__form-continue {
    cursor: pointer;
    box-sizing: border-box;
    margin: 20px auto 20px auto;
    display: flex;
    width: 335px;
    max-width: 335px;
    height: 62px;
    padding: 20px 66px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #7F43FF;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    transition:
        background 0.1s ease,
        box-shadow 0.1s ease,
        transform 0.1s ease;
}

.new-questionnaire__form-continue:hover {
    background: #712fff;
    box-shadow: 0px 0px 7px #7f43ff72;
}

.new-questionnaire__form-continue:active {
    transform: scale(0.96);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.new-questionnaire__form-continue.disabled {
    background: #BFA0FF;
    cursor: not-allowed;
}

.new-questionnaire__desktop-form-item {
    cursor: pointer;
    box-sizing: border-box;
    margin: 40px auto;
    display: flex;
    width: 335px;
    height: 62px;
    padding: 20px 66px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #2EBB49;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    transition: background 0.1s ease, box-shadow 0.1s ease;
}

.new-questionnaire__desktop-form-item:hover {
    background: #2EBB49;
    box-shadow: 0px 0px 7px #2EBB49;
}

.new-questionnaire__bank-accounts-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
    max-width: 400px;
}

.new-questionnaire__desktop-form-back-button {
    margin: 20px auto 0px;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    width: 150px;
    padding: 5px 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #7F43FF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.new-questionnaire__desktop-form-primary-button {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    text-align: center;
    height: 62px;
    padding: 20px 28px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #7F43FF;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    transition:
        background 0.1s ease,
        box-shadow 0.1s ease,
        transform 0.1s ease; /* Add transform for smooth scaling */
}

.new-questionnaire__desktop-form-primary-button:hover, .new-questionnaire__onboarding-welcome-button-purple:hover {
    background: #712fff;
    box-shadow: 0px 0px 7px #7f43ff72;
}

.new-questionnaire__desktop-form-primary-button:active, .new-questionnaire__onboarding-welcome-button-purple:active {
    transform: scale(0.96); /* Slightly shrink the button on click */
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2); /* Add a pressed shadow */
}

.new-questionnaire__desktop-form-secondary-button {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    height: 62px;
    padding: 20px 28px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #F3F3F3;
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    transition:
        background 0.1s ease,
        box-shadow 0.1s ease,
        transform 0.1s ease;
}

.new-questionnaire__desktop-form-secondary-button:hover {
    background: #e7e7e7;
    box-shadow: 0px 0px 7px #dedede72;
}

.new-questionnaire__desktop-form-secondary-button:active {
    transform: scale(0.96);
    box-shadow: 0px 0px 7px #dedede72;
}

.new-questionnaire__desktop-form-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 54px;
}

.new-questionnaire__select-one-question-input-wrapper {
    margin-top: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 12px;
}

.new-questionnaire__select-one-question-additional-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin-top: 20px;
}

.new-questionnaire__select-one-question-additional-input-amount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #000;
    text-align: right;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    border-radius: 10px;
    border: 1px solid #F3F3F3;
    padding: 8px;
    gap: 4px;
}

.new-questionnaire__select-one-question-additional-input-button{
    cursor: pointer;
    padding: 12px;
}

.new-questionnaire__date-question-input {
    border: none;
    box-shadow: none !important;
    outline: none;
    text-align: center;
    padding: 0px;
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: auto;
    flex: 0 1 auto;
    min-width: 20px;
    caret-color: @brand;
}

.new-questionnaire__date-question-input::-webkit-outer-spin-button,
.new-questionnaire__date-question-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.new-questionnaire__steps-date-picker {
    border: none;
    box-shadow: none !important;
    outline: none;
    padding-left: 39px;
}
.new-questionnaire__steps-date-picker .ant-picker-input input {
    color: #121212 !important;
    text-align: center;
    font-family: Poppins  !important;
    font-size: 24px  !important;
    font-style: normal  !important;
    font-weight: 400  !important;
    line-height: 32px  !important;
}

.new-questionnaire__onboarding-welcome-wrapper {
    position: fixed;
    padding: 32px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 32px;
    z-index: 100;
    background: #fff;
    overflow: auto;
    justify-content: center;
    align-items: center;
}

.new-questionnaire__onboarding-welcome-left,
.new-questionnaire__onboarding-welcome-right {
    flex: 1;
    max-width: 50%;
    height: 100%;
    border-radius: 20px;
}

.new-questionnaire__onboarding-welcome-left {
    background: url("../../assets/images/new-questionnaire/onboarding/welcome.png") no-repeat center center;
    background-size: cover;
}

.new-questionnaire__onboarding-welcome-right-content {
    width: 388px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.new-questionnaire__onboarding-welcome-name-title {
    color: #0C1421;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.36px;
    width: 100%;
    text-align: center;
}

.new-questionnaire__onboarding-welcome-sharing-preferences-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 24px;
}

.new-questionnaire__onboarding-welcome-sharing-preference {
    position: relative;
    width: 100%;
    height: 64px;
    display: flex;
    box-sizing: border-box;
    padding: 10px;
    align-items: center;
    gap: 6px;
    border-radius: 12px;
    border: 1px solid #C2C2C2;
    transition: box-shadow 0.3s, border 0.3s;

    &:hover {
        border: 1px solid #7F43FF;
        box-shadow: 0 0 8px 0 #e4d7ff, 0 0 4px #7F43FF;
    }
}

.new-questionnaire__onboarding-welcome-sharing-preference-selected {
    .new-questionnaire__onboarding-welcome-sharing-preference();
    border: 1px solid #7F43FF;
    background: #EFE8FF;
}

.new-questionnaire__onboarding-welcome-sharing-preference-badge {
    position: absolute;
    padding: 0 10px;
    top: -10px;
    right: -10px;
    border-radius: 12px;
    border: 1px solid #EFE8FF;
    background: #7F43FF;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 510;
    line-height: 22px;
    letter-spacing: -0.12px;
}

.new-questionnaire__onboarding-welcome-sharing-preference-title {
    color: #707070;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.new-questionnaire__onboarding-welcome-sharing-preference-title-selected {
    .new-questionnaire__onboarding-welcome-sharing-preference-title();
    color: #000
}

.new-questionnaire__onboarding-welcome-secure {
    color: #C2C2C2;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.07px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;
}

.new-questionnaire__onboarding-welcome-desktop {
    display: flex;
    width: 455px;
    padding-bottom: 60px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #FFF;
    margin-top: 54px;
    margin: 0;
}

.new-questionnaire__onboarding-welcome-paragraph {
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.2px;
}

.new-questionnaire__onboarding-more-details-title {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.48px;
    text-align: left;
}

.new-questionnaire__onboarding-more-details-description {
    color: @black;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
}

.new-questionnaire__onboarding-welcome-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.new-questionnaire__onboarding-welcome-top-banner {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 0px;
    border-radius: 20px 20px 0px 0px;
}

.new-questionnaire__onboarding-welcome-content {
    display: flex;
    max-width: 335px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.new-questionnaire__onboarding-welcome-title {
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 27px;
    font-style: normal;
    font-weight: 800;
    margin: 40px 0 0 0;
    line-height: 42px;
    letter-spacing: -0.28px;
}

.gradient-text {
    background: var(--Gradient_Purple, linear-gradient(84deg, #B277FF 11.78%, #6C27FF 74.16%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.new-questionnaire__onboarding__calendar {
    display: flex;
    padding: 12px;
    align-items: center;
    border-radius: 10px;
    border: 1px solid @grey4;
    gap: 7px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.new-questionnaire__onboarding-welcome-description {
    color: @black;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.2px;
}

.new-questionnaire__onboarding-welcome-features {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 22px;
}

.new-questionnaire__onboarding-welcome-features-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.new-questionnaire__onboarding-welcome-features-item-text {
    color: #444;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.191px;
}

.new-questionnaire__onboarding-welcome-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.new-questionnaire__onboarding-welcome-button, .new-questionnaire__onboarding-welcome-button-purple {
    box-sizing: border-box;
    display: flex;
    width: 335px;
    height: 64px;
    padding: 22px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid #FFF500;
    background: #000;
    cursor: pointer;
    transition: box-shadow 0.1s ease-in-out;
}

.new-questionnaire__onboarding-welcome-button-purple {
    background: @secondary;
}

.new-questionnaire__onboarding-welcome-button:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.3), 0 0 8px rgba(255, 245, 0, 0.5);
}

.new-questionnaire__onboarding-welcome-button-white {
    box-sizing: border-box;
    display: flex;
    width: 335px;
    height: 64px;
    padding: 22px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border-radius: 100px;
    cursor: pointer;
}

.new-questionnaire__onboarding-welcome-button-white:hover {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1), 0 0 6px rgba(255, 245, 0, 0.3);
}

.new-questionnaire__onboarding-welcome-button-text {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.new-questionnaire__onboarding-more-details-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
    background: rgba(0, 0, 0, 0.20);
}

.new-questionnaire__onboarding-more-details {
    margin: auto;
    width: 533px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF;
    padding: 30px;
    box-sizing: border-box;
}

.new-questionnaire__onboarding-more-details-slide {
    width: 413px;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;
    cursor: pointer;
}

.new-questionnaire__onboarding-more-details-slide-title {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 42px;
    letter-spacing: -0.24px;
    max-width: 335px;
    margin-top: 30px;
}

.new-questionnaire__onboarding-more-details-slide-text {
    color: @grey10;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 20px;
    max-width: 335px;
}

.swiper-pagination-bullet-active {
    background-color: #7F43FF !important;
}

.new-questionnaire__onboarding-more-details-button {
    box-sizing: border-box;
    margin: 0px auto;
    display: flex;
    padding: 20px 66px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 413px;
    gap: 10px;
    align-self: stretch;
    border-radius: 100px;
    background: #7F43FF;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;
    transition: box-shadow 0.3s;
}

.new-questionnaire__onboarding-more-details-button:hover {
    box-shadow: 0 0 8px 0 #e4d7ff, 0 0 4px #7F43FF;
}

.new_questionnaire__onboarding-more-details-button-container {
    margin-top: 20px;
}

.new-questionnaire__onboarding-more-details-back-button {
    padding: 10px;
    cursor: pointer;
    color: #7F43FF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    width: 250px;
    text-align: center;
    margin: 10px auto 0px;
}

.new-questionnaire__bank-account {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.new-questionnaire__bank-account-title {
    text-align: center;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.1px;
}


.new-questionnaire__mobile-header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 10px 10px;
}

.new-questionnaire__mobile-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-questionnaire__mobile-form-back {
    padding: 10px;
}

.new-questionnaire__bank-accounts-modal,
.new-questionnaire__refund-modal {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 15px;
}

.new-questionnaire__bank-accounts-close,
.new-questionnaire__refund-close {
    position: absolute;
    top: -36px;
    right: -20px;
}

.new-questionnaire__mobile-form-wrapper {
    max-width: 500px;
    margin: 0 auto;
}

.new-questionnaire__mobile-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.new-questionnaire__mobile-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px auto;
    padding: 5px 20px;
    box-sizing: border-box;
    overflow-x: auto;
    gap: 7px;
    position: relative;
    scroll-behavior: smooth;
    max-width: 100%;
}

.new-questionnaire__mobile-menu::-webkit-scrollbar {
    display: none !important;
}

.new-questionnaire__mobile-menu {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.new-questionnaire__mobile-step {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 7px 12px;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.07px;
    white-space: nowrap;
    transition: transform 0.3s ease;
}

.new-questionnaire__mobile-step--done {
    cursor: pointer;
    color: #ABABAB;
}

.new-questionnaire__mobile-step--undone, .new-questionnaire__mobile-step--inProgress {
    cursor: pointer;
    color: #707070;
}

.new-questionnaire__mobile-step--active {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #EFE8FF;
    color: #7F43FF;
}

.new-questionnaire__mobile-step--disallowed {
    cursor: not-allowed;
    color: @grey3;
}

.new-questionnaire__bank-list_meta {
    flex-direction: row;
    align-items: center !important;
}

.new-questionnaire__bank-option-list-item {
    cursor: pointer;
}

.new-questionnaire__img-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px ;
    margin-top: 40px;
}

.new-questionnaire__connect-modal__prompt-container {
   width: 335px;
}

.new-questionnaire__connect-modal .ant-modal-body {
    padding: 0px !important;
}

.new-questionnaire__bank-accounts-text2 {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.new-questionnaire__accounts-cards-container {
    width: 350px;
}

.new-questionnaire__account-card-container {
    box-sizing: border-box;
    padding: 7px 7px 30px 7px;
    width: 100%;
    height: 100%;
}

.new-questionnaire__account-card {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 335px;
    padding: 16px;
    justify-content: center;
    align-items: flex-start;
    border-radius: 20px;
    border: 1px solid #F3F3F3;
    background: #FFF;
    box-shadow: 0px -1.818px 9.375px 0px rgba(166, 185, 255, 0.1), 0px 1.818px 9.375px 0px rgba(166, 185, 255, 0.1);
}

.new-questionnaire__account-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.new-questionnaire__account-info-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.new-questionnaire__account-name {
    color: #555;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.new-questionnaire__account-balance {
    color: #121212;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.new-questionnaire__account-iban {
    color: #7F43FF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.new-questionnaire__account-description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.new-questionnaire__account-description-text {
    color: #555;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
}

.new-questionnaire__income-expenses-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-questionnaire__income-expenses-explainer {
    color: #707070;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    max-width: 335px;
    margin-bottom: 20px;
}

.new-questionnaire__profile-name {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: #707070;
    background-color: @grey2;
    padding: 5px 15px;
    border-radius: 30px;
}

.new-questionnaire__income-expenses-title {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 10px;

}

.new-questionnaire_income-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000020;
    transition: background-color 0.1s ease;
    z-index: 1;
}

.new-questionnaire_income-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 561px;
    box-sizing: border-box;
    padding: 60px;
    margin: 118px auto;
    border-radius: 20px;
    background: #FFF;
}

.new-questionnaire_income-modal-title {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1px;
    text-align: center;
}

.new-questionnaire_income-modal-text {
    color: #707070;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

}

.new-questionnaire__income-card{
    box-sizing: border-box;
    width: 335px;
    margin-top: 30px;
    display: flex;
    padding: 20px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 30px;
    background: #FFF;
    box-shadow: 0px -4px 10px 0px rgba(100, 133, 249, 0.10), 0px 3px 10px 0px rgba(100, 133, 249, 0.10);
}

.new-questionnaire__income-card-amount {
    color: #121212;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.new-questionnaire__income-card-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.new-questionnaire__income-card-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.new-questionnaire__income-card-merchant {
    color: #2EBB49;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.new-questionnaire__income-card-description {
    color: #707070;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.new-questionnaire__amount-card-wrapper {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    padding: 4px 4px 4px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #F3F3F3;
}

.new-questionnaire__income-card-total-text {
    color: #707070;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.new-questionnaire__income-card-total-amount-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    height: 42px;
    padding: 0px 12px;
    border-radius: 4px;
    background: rgba(0, 125, 238, 0.05);
    cursor: pointer;
}

.new-questionnaire__income-card-total-amount {
    color: @lightBlue;
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.new-questionnaire__income-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 26px;
    width: 100%;
    margin-top: 20px;
}

.new-questionnaire__income-edit-text {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.new-questionnaire__income-card-total-input {
    border: none;
    background-color: transparent;
    text-align: flex-end;

}

.new-questionnaire__income-report {
    box-sizing: border-box;
    padding: 40px 16px;
    position: relative;
    width: 335px;
    height: 375px;
    border-radius: 20px 20px 0px 0px;
    background: url("../../assets/images/new-questionnaire/tear-off-receipt.svg") no-repeat center center;
    box-shadow: 0px -4px 32px rgba(102, 102, 102, 0.16)
}

.new-questionnaire__income-report__success-icon {
    position: absolute;
    top: -38px;
    left: 123px;
    display: block;
    z-index: 2;
}

.new-questionnaire__income-report__title, .new-questionnaire__income-no-report__title {
    color: #121212;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.new-questionnaire__income-no-report {
    display: block;
    margin:auto;
    width: 335px;

}

.new-questionnaire__income-no-report__text {
    margin-top: 20px;
    color: @grey10;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 6px;
    max-width: 335px;
}

.new-questionnaire__income-report__text {
    color: @grey10;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    line-height: 14px;
    margin-top: 20px;
}

.new-questionnaire__income-report-total-amount-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    height: 42px;
    padding: 0px 10px;
    cursor: pointer;
}

.new-questionnaire__income-report__sub-title {
    color: @grey10;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.new-questionnaire__income-report-total-amount {
    color: #121212;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.new-questionnaire__amount-question-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 110px;
    margin-right: 10px;
}

.new-questionnaire__income-report__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-questionnaire__expenses-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-questionnaire__edit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.expenses__edit-card {
    box-sizing: border-box;
    padding: 20px;
    width: 335px;
    height: 100%;
    background: #fff;
    border-radius: 30px;
    box-shadow: 0px -4px 10px 0px rgba(100, 133, 249, 0.1), 0px 3px 10px 0px rgba(100, 133, 249, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.new-questionnaire__transactions-edit-title {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1px;
}

.new-questionnaire__mobile-transactions-edit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
    background: #fff;
}

.new-questionnaire__transactions-edit-list {
    overflow-y: scroll;
    min-width: 335px;
    height: calc(100% - 100px);
    padding-bottom: 100px;
}

.new-questionnaire__transactions-edit-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.new-questionnaire__transactions-edit-text {
    color: #121212;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.new-questionnaire__transactions-edit-mobile-title {
    color: @grey10;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.new-questionnaire__expense-report {
    box-sizing: border-box;
    padding: 16px 16px;
    position: relative;
    width: 335px;
    height: auto;
    border-radius: 20px;
    box-shadow: 0px -4px 32px rgba(102, 102, 102, 0.16);
}

.new-questionnaire__object-question-tear-off {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    position: relative;
    gap: 6px;
    width: 335px;
    border-radius: 20px;
    box-shadow: 0px -4px 32px rgba(102, 102, 102, 0.16)
}
.new-questionnaire__expense-report-card {
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow-y: auto;
}

.new-questionnaire__expense-report-card::-webkit-scrollbar {
    width: 6px;
    height: 5px;
}

.new-questionnaire__expense-report-card::-webkit-scrollbar-thumb {
    background-color: @grey0;
    border-radius: 4px;
}

.new-questionnaire__income-report-card {
    display: flex;
    flex-direction: column;
    min-height: 280px;
}

.new-questionnaire__select-one-option__question-options {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 335px;
    min-width: 335px;
}

.new-questionnaire__select-one-optionV2__question-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin-top: 18px;
}

.new-questionnaire__select-one-optionV2__question-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 163px;
    min-height: 102px;
    padding: 10px 20px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 8px;
    background: #FFF;
    border: 1px solid #DDDDDD;
}

.new-questionnaire__select-one-optionV2__question-option.selected {
    border: 1.5px solid #7F43FF;
    background: #EFE8FF;
}

.new-questionnaire__select-one-optionV2__question-option.disabled {
    opacity: 0.5;
    pointer-events: none;
    filter: grayscale(30%);
    background: #fff;
}

.new-questionnaire__select-one-option__question-options::-webkit-scrollbar {
    width: 6px;
    height: 5px;
}

.new-questionnaire__select-one-option__question-options::-webkit-scrollbar-thumb {
    background-color: @grey0;
    border-radius: 4px;
}

.new-questionnaire__select-one-option__question-option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 0px;
    cursor: pointer;
    box-sizing: border-box;
}

.new-questionnaire__select-one-option__question-option-disabled {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    cursor: default;
    box-sizing: border-box;
}

.new-questionnaire__select-one-option__text {
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.new-questionnaire__text-question-input {
    border: none;
    box-shadow: none !important;
    outline: none;
    text-align: center;
    padding: 0px;
    color: #121212;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.new-questionnaire__desktop-form-back-to-swipe {
    cursor: pointer;
    box-sizing: border-box;
    position: fixed;
    bottom: 150px;
    right: 100px;
    display: inline-flex;
    height: 42px;
    padding: 9px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 4;
    flex-shrink: 0;
    border-radius: 56.25px;
    background: #FFF;
    box-shadow: 0px -2.25px 5.625px 0px rgba(100, 133, 249, 0.10), 0px 1.688px 5.625px 0px rgba(100, 133, 249, 0.10);
}

.new-questionnaire__mobile-form-back-to-swipe {
    cursor: pointer;
    box-sizing: border-box;
    display: inline-flex;
    height: 42px;
    padding: 9px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 4;
    flex-shrink: 0;
    border-radius: 56.25px;
    background: #FFF;
    box-shadow: 0px -2.25px 5.625px 0px rgba(100, 133, 249, 0.10), 0px 1.688px 5.625px 0px rgba(100, 133, 249, 0.10);
}

.new-questionnaire__desktop-form-back-to-swipe-text {
    color: #7F43FF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.transactions-list-view__container {
    height: calc(100vh - 328px);
    overflow: auto;
    position: relative;
}

.expenses__list-month {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-content: flex-start;
    box-sizing: border-box;
    color: @grey10;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.38px;
}

.expenses__list-card-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.expenses__list-card-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.expenses__list-card-merchant-description {
    color: #2EBB49;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.expenses__list-transactions-count {
    color: #505050;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
}

.expenses__list-card-tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.expenses__list-card-tag {
    text-align: center;
    display: inline-flex;
    padding: 4px 10px;
    font-size: 13px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #F3F3F3;
}

.expenses__list-card-checkbox {
    padding: 4px;
    box-sizing: border-box;
}

.expenses__list-card-amount-value {
    color: #000;
    text-align: right;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.select-all-container {
    display: flex;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    justify-content: flex-end;
    align-items: flex-end;
}

.expenses__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 0;
    transition: height 0.5s ease;
    opacity: 0;
}

.expenses__list.expanded {
    height: auto;
    opacity: 1;
    transition: height 0.5s ease, opacity 0.5s ease;
}

.expenses__list-card-content {
    position: relative;
    width: 341px;
    height: auto;
    padding: 12px;
    margin: 5px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px -4px 10px 0px rgba(100, 133, 249, 0.10), 0px 3px 10px 0px rgba(100, 133, 249, 0.10);
    opacity: 0;
    transform: translateY(-200px);
    transition: height 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
}

.expenses__list.expanded .expenses__list-card-content {
    opacity: 1;
    transform: translateY(0);
}

.new-questionnaire__form-continue-wrapper {
    position: fixed;
    background: linear-gradient(180deg, #ffffff00 0%, #ffffff 100%);
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.new-questionnaire__mobile-form-continue-wrapper {
    position: fixed;
    height: 120px;
    background: linear-gradient(180deg, #ffffff00 0%, #ffffff 100%);
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.expenses__list-card-edit-button {
    border-radius: 10px;
    background: #E6F3FF;

    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 4px 10px;
    font-size: 13px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.new-questionnaire__list-confirm-status {
    position: absolute;
    top: 250px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    min-width: 300px;
    max-width: 350px;
}

.new-questionnaire__income-manual__section-title {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.new-questionnaire__income-manual__section-subtitle {
    color: #707070;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    @media (max-width: 768px) {
        width: 335px;
        color: #000;
    }
}

.new-questionnaire__income-manual__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 335px;
}

.new-questionnaire__income-manual__action-title {
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 42px;
}

.new-questionnaire__income-manual__action-subtitle {
    color: @grey10;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.new-questionnaire__income-manual__image-container {
    display: flex;
    justify-content: center;

    img {
        width: auto;
        height: 215px;

        @media (max-width: 768px) {
            height: 143px;
        }
    }
}

.new-questionnaire__upload-files__question {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 335px;
    width: 100%;
}

.new-questionnaire__upload-files__question-dragger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 335px;
    background: #fff!important;
    cursor: pointer;

    .ant-upload-drag-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px dashed #7F43FF;
        border-radius: 8px;
        padding: 24px;


    }
}

.new-questionnaire__upload-files__question-take-photo-button {
    display: flex;
    align-items: center;
    margin: auto;
    color:  #7F43FF;
    border-color: #7F43FF;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

}

.new-questionnaire__upload-files__question-dragger-text {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 20px;
}

.new-questionnaire__upload-files__question-dragger-divider {
    .ant-divider-inner-text {
        color: #6D6D6D;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }
}

.new-questionnaire__upload-files__question-files {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 335px;
    width: 100%;
}

.new-questionnaire__upload-files__question-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid #E7E7E7;
}

.new-questionnaire__upload-files__question-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.new-questionnaire__upload-files__question-info-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}

.new-questionnaire__upload-files__question-file-name {
    font-size: 12px;
    font-weight: 600;
    font-family: Poppins;
    max-width: 200px;
}

.new-questionnaire__mobile-form-swipe-selector-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 20px;
    padding: 4px;
    gap: 4px;
    border-radius: 100px;
    background: #F3F3F3;
}

.new-questionnaire__mobile-form-swipe-selector {
    cursor: pointer;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #F3F3F3;
    color: #ABABAB;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    transition: background 0.3s, color 0.3s;
}

.new-questionnaire__mobile-form-swipe-selector.active {
    background: #FFF;
    color: #000;
}

.new-questionnaire__question-icon {
    margin-left: 5px;
    cursor: pointer;
}

.new-questionnaire__text-question-unit {
    color: #C4C4C4;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.new-questionnaire__array-v2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-questionnaire__array-v2-question-text {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.2px;
    width: 100%;
    max-width: 335px;
}

.new-questionnaire__array-v2-info-text {
    color: #707070;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 335px;
    width: 100%;
    white-space: pre-line;
    margin: 10px 0 0;
}

.new-questionnaire__array-v2-add-button {
    max-width: 335px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    box-sizing: border-box;
    min-height: 70px;
    height: 70px;
    border-radius: 8px;
    border: 1px solid #7F43FF;
    background: #FFF;
    color: #7F43FF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    &:hover {
        box-shadow: 0px 0px 7px #7f43ff72;
    }
}

.new-questionnaire__array-v2-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    margin-top: 12px;
}

.new-questionnaire__array-v2-item-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.new-questionnaire__array-v2-item {
    display: flex;
    box-sizing: border-box;
    width: 287px;
    height: 68px;
    padding: 12px 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #F3F3F3;
    background: #FBFBFB;
    color: #121212;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.new-questionnaire__question-title-v2 {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.65px;
    width: 100%;
    max-width: 335px;
    text-align: left;
    padding: 20px 20px 0px;
    box-sizing: border-box;
}

.new-questionnaire__list-question {
    margin-bottom: 32px;
    max-width: 345px;
    margin: auto;
}

.new-questionnaire__list-question-title {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    box-sizing: border-box;
    width: 335px;
    margin: auto;
}

.new-questionnaire__text-list-question-input {
    border: none;
    box-shadow: none !important;
    outline: none;
    text-align: center;
    padding: 0px;
    margin-left: 30px;
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: auto;
    flex: 0 1 auto;
    min-width: 20px;
    caret-color: #2ebb49;
}

.ant-drawer-header-title {
    flex-direction: row-reverse;
}

.new-questionnaire__review-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    height: 100%;
    width: 100%;
    border-radius: 30px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px -4px 10px 0px rgba(100, 133, 249, 0.10), 0px 3px 10px 0px rgba(100, 133, 249, 0.10);
}

.new-questionnaire__review-page__title {
    color: @black;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.new-questionnaire__review-page__steps--mobile {
   display: flex;
   flex-wrap: wrap;

   .ant-steps-item {
    text-align: start;
    min-height: 100px;

        .ant-steps-item-container {
            display: flex;
            margin: 0px auto;
            .ant-steps-item-content {
                width: 100%;
            }
            .ant-steps-item-tail::after {
                width: 3px!important;
                margin-left: 12px;
                height: 120%;
            }

            .ant-steps-item-content {
                .ant-steps-item-title {
                    color: #121212;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    display: flex;
                    flex-direction: column;

                    .ant-steps-item-subtitle {
                        color: #707070;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                        text-align: start;
                        padding: 10px 0px;
                    }
                }
                .new-questionnaire__review-page__complete-button {
                    color: @secondary;
                    background: @white;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    padding: 10px 20px;
                    border-radius: 50px;
                    cursor: pointer;
                    text-align: center;
                    margin-bottom: 10px;
                    border: 1px solid @secondary;
                    width: max-content;
                }
            }
        }
    }
}

.new-questionnaire__review-page__steps--desktop {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;

   .ant-steps-item {
    text-align: start;
    max-width: 32%;
    min-width: 32%;

        .ant-steps-item-container {
            display: flex;
            flex-direction: column  ;
            margin: 0px auto;
            .ant-steps-item-content {
                width: 100%;

            }
            .ant-steps-item-icon {
                width: 100%;
                margin: 0 auto;
                margin-inline-start: 0px!important;
            }
            .ant-steps-item-tail {
               display: none;
            }

            .ant-steps-item-content {
                .ant-steps-item-title {
                    color: #121212;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    display: flex;
                    flex-direction: column;

                    .ant-steps-item-subtitle {
                        color: #707070;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        line-height: normal;
                        text-align: start;
                        padding: 10px 0px;
                        white-space: normal;
                    }
                }
                .new-questionnaire__review-page__complete-button {
                    color: @secondary;
                    background: @white;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    padding: 10px 30px;
                    border-radius: 50px;
                    cursor: pointer;
                    text-align: center;
                    width: fit-content;
                    margin-bottom: 10px;
                    margin-left: auto;
                    margin-right: auto;
                    border: 1px solid @secondary;
                }
            }
        }
    }
}

.new-questionnaire__select-many-tiles__question-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin-top: 18px;
}

.new-questionnaire__select-many-tiles__checkbox {
    position: absolute;
    top: 10px;
    right: 10px;
}

.new-questionnaire__select-many-tiles__question-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 163px;
    min-height: 102px;
    padding: 10px 20px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 8px;
    background: #FFF;
    border: 1px solid #DDDDDD;
}

.new-questionnaire__select-many-tiles__question-option.selected {
    border: 1.5px solid #7F43FF;
    background: #EFE8FF;
}

.new-questionnaire__select-many-tiles__question-option.disabled {
    opacity: 0.5;
    pointer-events: none;
    filter: grayscale(30%);
    background: #fff;
}

.new-questionnaire__select-many-tiles__text {
    color: #707070;
    text-align: left;
    font-family: Poppins;
    line-height: normal;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.07px;
    margin-top: 8px;
}

@media (min-width: 1110px) {
    .new-questionnaire__select-many-tiles__question-option:hover {
        background: #EFE8FF;
        box-shadow: 0px -4px 14px 0px rgba(166, 185, 255, 0.10), 0px 1.818px 9.375px 0px rgba(166, 185, 255, 0.10);
    }

    .new-questionnaire__select-one-optionV2__question-option:hover {
        background: #EFE8FF;
        box-shadow: 0px -4px 14px 0px rgba(166, 185, 255, 0.10), 0px 1.818px 9.375px 0px rgba(166, 185, 255, 0.10);
    }
}


@media (max-width: 1109px) {
    .new-questionnaire__onboarding-welcome-top-banner {
        border-radius: 0;
        padding: 12px 0;
    }

    .new-questionnaire__onboarding-more-details-wrapper {
        background-color: #FFF;
        overflow: auto;
    }

    .new-questionnaire__onboarding-more-details {
        width: 341px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        border-radius: 0;
        margin: 20px auto 150px;
        height: fit-content;
    }

    .new-questionnaire__onboarding-more-details-slide {
        width: 335px;
        margin: 30px auto;
    }

    .new-questionnaire__onboarding-more-details-button  {
        width: 335px;
    }

    .new_questionnaire__onboarding-more-details-button-container {
        position: fixed;
        width: 100%;
        padding-top: 10px;
        background: white;
        height: 120px;
        left: 0;
        bottom: 0;
        margin-top: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .new-questionnaire__bank-accounts {
        margin-top: 8px;
        margin-right: 10px;
        margin-bottom: 0px;
    }

    .new-questionnaire__connect-modal__prompt-container {
        padding: 10px;
     }

    .new-questionnaire__refund {
        margin-top: 8px;
        margin-bottom: 0px;
    }

    .new-questionnaire__bank-accounts-list {
        gap: 5px;
    }

    .new-questionnaire__add-bank-account-button {
        font-family: Poppins;
        padding: 0px;
        width: 100%;
    }

    .new-questionnaire__error-message {
        padding: 0 20px;
    }

    .new-questionnaire__question-text {
        margin-top: 10px;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        width: 335px;
        max-width: 335px;
        text-align: left;
    }

    .new-questionnaire__accounts-cards-container {
        width: 335px;
    }

    .new-questionnaire__account-card {
        width: 320px;
    }

    .new-questionnaire__bank-accounts-text2 {
        max-width: 335px;
    }

    .new-questionnaire_income-modal-content {
        width: 335px;
        padding: 30px 12px 20px;
        margin: 162px auto;
    }

    .transactions-list-view__container {
        height: auto;
        max-width: 345px;
        margin: 16px auto;
    }

    .expenses__list-month {
        font-size: 12px;
    }

    .select-all-container {
        align-items: center;
        margin-top: 5px;
    }

    .new-questionnaire__list-confirm-status {
        top: 150px;
        font-size: 14px;
    }

    .new-questionnaire__mobile-form-swipe-selector-container {
        margin-top: 0;
    }

    .new-questionnaire__onboarding-welcome-wrapper {
        padding: 0px;
    }

    .new-questionnaire__onboarding-welcome-right {
        max-width: 100%;
        border-radius: 0px;
    }

    .new-questionnaire__onboarding-welcome-name-title {
        font-size: 24px;
    }

    .new-questionnaire__onboarding-welcome-right-content {
        width: 341px;
    }

    .new-questionnaire__onboarding-welcome-paragraph {
        font-size: 18px;
    }

    .new-questionnaire__onboarding-welcome-sharing-preferences-container {
        width: 90%;
        margin: 20px auto;
    }

    .new-questionnaire__review-page {
        width: 335px;
        margin: auto;
        box-shadow: none;
        border: none;
    }

    .new-questionnaire__review-page__title {
        color: #121212;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 175% */
    }
}

@media (max-width: 500px) {
    .custom-prompt-content {
        width: 340px;
    }

    .new-questionnaire__bank-accounts-buttons {
        max-width: 345px;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .new-questionnaire__array-v2-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
        margin-top: 12px;
    }

    .new-questionnaire__upload-files__question-dragger {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 150px;
        width: 335px;
        background: #fff!important;
        cursor: pointer;

        .ant-upload-drag-container {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border: 1px dashed #7F43FF;
            border-radius: 8px;
            padding: 24px;
        }
    }
}

@media (max-width: 400px) {
    .new-questionnaire__onboarding-welcome-title{
        margin: 20px 0 0 0;
    }
    .new-questionnaire__onboarding-welcome-features{
        margin-bottom: 10px;
        margin-top: 12px;
    }
    .new-questionnaire__onboarding-welcome-description{
        font-size: 16px;
    }
    .new-questionnaire__onboarding-welcome-buttons{
        margin-top: 15px;
        gap:0
    }
    .new-questionnaire__form-title {
        max-width: 340px;
    }
    .new-questionnaire__onboarding-welcome-button-purple {
        width: 335px;
    }
}

@primary-color: #2ebb49;