.summary-table-entry-outer-div{
  margin: 10px auto;
  border-radius: 6px;
  color: #707070;
  font-family: Poppins, serif;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}
