@import "../common/colors.less";

.document_parse-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 10px auto;
}

.document-parse__array {
    .ant-upload-wrapper {
        .ant-upload-select {
            width: 100%;
        }
    }
}

.document-parse__array-item-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.document-parse__array-v2-item {
    display: flex;
    width: 287px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
} 

.document-parse-close {
    color: @blue;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 22px;
    letter-spacing: -0.1px;
    cursor: pointer;
}

.document-parse-drawer .ant-drawer-body,
.document-parse-drawer .ant-drawer-content,
.document-parse-drawer .ant-drawer-header {
    background-color: @grey1 !important;
}

.document-parse__array-item, .document-parse__array-upload {
    display: flex;
    width: 100%;
    height: 68px;
    box-sizing: border-box;
    padding: 12px 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid @white;
    background: @white;
    color: @black;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
}

.document-parse__array-upload {
    display: block;
    height: 110px
}

.document-parse-continue, .document-parse-continue-desktop, .document-parse-continue-grey-desktop, .document-parse-continue-outline {
    cursor: pointer;
    min-width: 335px;
    border-radius: 100px;
    background: @secondary;
    color: @white;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    border: none;
    display: flex;
    width: 90%;
    height: 44px;
    padding: 8px 11px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #8147FF, 0px 1px 3px 0px #9E70FF, 0px -2.4px 0px 0px #8852FB inset;
}

.document-parse-continue-grey-desktop, .document-parse-continue-outline {
    color: @black;
    background: @white;
    box-shadow: 0px 0px 0px 1px #7f43ff, 
            0px 1px 3px 0px rgba(143, 143, 143, 0.20), 
            0px -2.4px 0px 0px rgba(62, 62, 62, 0.04) inset;

}
.document-parse-continue-desktop {
    width: 335px;
    margin-bottom: 30px;
}

.document-parse-continue-grey-desktop {
    margin-top: 20px;
    width: 335px;
}

.document-parse__totals-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    background: @white;
    box-shadow: 0px -4px 10px 0px rgba(100, 133, 249, 0.10), 0px 3px 10px 0px rgba(100, 133, 249, 0.10);
    width: 300px;
}
.document-parse__upload {
    display: flex;
    gap: 5px;
    font-size: 14px;
}

.document_parse-mobile {     
    width: 345px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 10px auto;
}

.document-parse-outer-container-mobile {
    padding: 0;
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 100px;
  }
  .document-parse__ellipsis-text{
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 50%;
  }
@primary-color: #2ebb49;