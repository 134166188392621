@border1px: 1px solid rgba(112, 124, 140, 0.18);
@border2px: 2px solid rgba(112, 124, 140, 0.18);
@primary-border1px: 1px solid #6808FD;

.border-left-1px {
  border-left: @border1px;
}

.border-right-1px {
  border-right: @border1px;
}

.border-top-1px {
  border-top: @border1px;
}

.border-bottom-1px {
  border-bottom: @border1px;
}

.border-1px {
  border: @border1px;
}

.border-top-2px {
  border-top: @border2px;
}

.border-bottom-2px {
  border-bottom: @border2px;
}

.border-left-2px {
  border-left: @border2px;
}

.border-2px {
  border: @border2px;
}

.primary-border-1px {
  border: @primary-border1px;
}