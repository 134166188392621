@import "../common/colors.less";

.history__content {
    margin-top: 30px;
}

.history__collapse {
    margin-bottom: 20px;
    border-radius: 20px;
    background: @grey1;
    border: none;
    border-bottom: none;
    box-shadow: none;
    overflow: auto;
    .ant-collapse-content{
        background: @grey1;
    }
}

.history__collapse .ant-collapse-content{
    background: @grey1;
}

.history__collapse .ant-collapse-item{
    border-bottom: none;
}

.history__content-item-header {
    margin-left: 30px;
    position: relative;
    height: 108px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: @grey8;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
}

.history-chevron {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
}

.history__item {
    padding: 14px;
}

.history__item-row {
    justify-content: space-between;
}

.history__download-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.history__download-row {
    margin-top: 6px;
    cursor: pointer;
}

.history__content-item-title {
    color: @grey8;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
    margin-bottom: 6px;
}

.history__content-item-text {
    color: @grey7;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 6px;

}

.history__content-item-date {
    color: @grey5;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.history__download-text {
    color: #253BFF;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    line-height: 24px;
    margin-left: 3px;
}

@media (max-width: 1109px) {
    .history__content {
        width: 345px;
        margin-top: 10px;
    }

    .history__content-item-header {
        height: 48px;
        margin-left: 10px;
        font-size: 16px;
    }

    .history__collapse .ant-collapse-item .ant-collapse-header {
        padding: 10px;
    }

    .history-chevron {
        left: 10px;
    }

    .history__item {
        padding: 4px;
    }

    .history__item-row {
        align-items: center;
    }
}
@primary-color: #2ebb49;