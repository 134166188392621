@box-shadow-color: #6485F92A;
@font-family: Poppins;
@selected-bg-color: #EFE8FF;
@border-color: #7F43FF;
@placeholder-color: #ABABAB;
@day-color: #121212;
@outside-month-color: #808080;
@slash-color: #C4C4C4;
@focused-bg-color: #7F43FF;
@focused-text-color: white;
@menu-font-family: Inter;
@menu-text-color: #707070;
@selected-option-bg-color: #F8F7FA;
@selected-check-color: #4CAF50;

.custom-date-picker__wrapper {
    svg {
        display: none;
    }
}

.custom-date-picker__calendar {
    box-shadow: 0 0 10px 0 @box-shadow-color;
    font-family: Poppins;
    width: 390px;
    min-height: 365px;
    padding: 8px 34px 28px;
    border: none;
    box-sizing: border-box;

    .react-datepicker__header {
        border: none;
        background-color: white;
        font-family: Poppins;
    }

    .react-datepicker__header__dropdown,
    .react-datepicker__month-dropdown-container {
        text-align: left;
        margin: 0;
    }

    .react-datepicker__month {
        margin: 20px 0 0 -24px;
    }

    .react-datepicker__current-month,
    .react-datepicker__navigation {
        display: none;
    }

    .react-datepicker__month-select,
    .react-datepicker__year-select {
        border: none;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: @day-color;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__day--outside-month {
        font-weight: 400;
        text-align: center;
    }

    .react-datepicker__day-name {
        font-size: 12px;
        line-height: 16px;
        color: #3C3C4399;
        margin-top: 20px;
        margin-right: 20px;
    }

    .react-datepicker__day {
        font-size: 16px;
        height: 38px;
        width: 45px;
        line-height: 38px;
    }

    .react-datepicker__day--outside-month {
        color: @outside-month-color;
    }

    .react-datepicker__day--selected {
        background-color: @selected-bg-color;
        border-radius: 10px;
        color: @day-color;
    }
}

.custom-date-picker__input {
    font-family: Poppins;
    border: none;
    width: 296px;
    height: 39px;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    color: @day-color;
    outline: none;

    &::placeholder {
        color: @placeholder-color;
    }
}

.custom-date-picker__input-border {
    border: none;
    border-bottom: 4px solid @border-color;
    width: 296px;
    height: 39px;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    color: @day-color;
    outline: none;

    &::placeholder {
        color: @placeholder-color;
    }
}

.custom-date-picker__mobile {
    font-family: Poppins;

    .input-mobile {
        width: 300px;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        text-align: center;
        color: #C4C4C4;
        display: flex;
        justify-content: space-between;
    }

    .input-mobile-item {
        width: 34px;
        margin-right: 3px;
        text-align: center;
    }
    
    .input-mobile-item-border {
        border-bottom: 4px solid @border-color;
        width: 34px;
        margin-right: 3px;
        text-align: center;
    }
}

.custom-date-picker__selected {
    color: @day-color !important;
}

.custom-date-picker__slash {
    width: 20px;
    color: @slash-color !important;
}

.custom-date-picker__select-container {
    border-color: @border-color !important;

    .react-select__control {
        padding-left: 8px;
        border: none;
        box-shadow: none;

        &--is-focused {
            border-color: @border-color !important;
        }
    }

    .react-select__menu-list {
        max-height: 490px;
        font-family: @menu-font-family;
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: @menu-text-color;
        cursor: pointer;
        font-family: Poppins;
    }

    .react-select__option {
        position: relative;
        font-weight: 400;
        font-family: Poppins;

        &--is-selected {
            background-color: @selected-option-bg-color;
            color: @day-color;
            font-weight: 600;

            &::after {
                content: "";
                position: absolute;
                width: 6px;
                height: 12px;
                border-right: 3px solid @selected-check-color;
                border-bottom: 3px solid @selected-check-color;
                transform: rotate(45deg);
                top: 10px;
                right: 20px;
            }
        }

        &--is-focused {
            background-color: @focused-bg-color;
            color: @focused-text-color;
        }
    }

    .react-select__value-container {
        padding: 0;
    }

    .react-select__single-value {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
    }

    .react-select__indicator-separator {
        display: none;
    }

    svg {
        display: block !important;
        fill: @border-color;
    }
}

@primary-color: #2ebb49;