@import "../common/colors.less";

.resizable-textarea {
    resize: vertical;
    max-height: 200px;
}

.payment-details__content {
    width: 730px;
    height: max-content;
    margin: 0 auto;
}

.payment-details__content-body {
    height: max-content;
    margin-top: 48px;
}

.payment-details__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.payment-details__content-heading {
    color: @black;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.4px;
}

.payment-details__form {
    margin-top: 24px;
    display: flex;
    // flex-direction: row;
    flex-direction: column;
    justify-content: space-between;
}

.payment-details__form-column {
    width: 345px;
    display: flex;
    flex-direction: column;
}

.payment-details__edit-button {
    display: flex;
    padding: 6px 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #EDF8FF;
    color: #1253FA;
    cursor: pointer;

    &:hover {
        background: #e3f4ff;
    }
}

.payment-details__save-button {
    width: 335px;
    height: 50px;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: @brand;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;

    &:not(:disabled):hover {
        background: @brandDark !important;
    }
}

.payment-details__input {
    height: 48px;
}

.payment-details__input-text,
.ant-picker .ant-picker-input>input[disabled],
.ant-picker .ant-picker-input>input {
    color: @black !important;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.payment-details__input-label .ant-form-item-label>label {
    color: @grey7;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ant-form-item-required:before {
    display: none !important;
}

.payment-details__mobile-edit-button-text {
    color: @brand;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    background: none;
    box-shadow: none;
    padding: 4px;
    border-bottom: 1px solid @brand;
}

.payment-details__mobile-save-button {
    position: fixed;
    z-index: 3;
    top: 0;
    width: 47px;
    height: 47px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    margin-left: 295px;
}

.payment-details__mobile-save-button .ant-btn-primary:disabled {
    border-color: transparent;
    border-bottom: 1px solid @grey3;
    border-radius: 0;
    background: none;
}

.payment-details__mobile-save-button .ant-btn-primary {
    border-bottom: 1px solid @brand;
    border-radius: 0;
    background: none;
}

.payment-details__mobile-save-button-text {
    color: @brand;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    background: none;
    box-shadow: none;
    padding: 0;
}

.payment-details__type-switch-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.payment-details__type-switch {
    cursor: pointer;
    display: flex;
    padding: 14px 32px;
    width: 179px;
    justify-content: center;
    align-items: center;
    border: 1px solid @grey4;
    background: @white;
}

.payment-details__type-switch-wrapper .left {
    border-radius: 10px 0px 0px 10px;
}

.payment-details__type-switch-wrapper .right {
    border-radius: 0px 10px 10px 0px;
}

.payment-details__type-switch-wrapper .active {
    border: 1px solid @brand;
    background: #EAF8ED;
    color: @brand;
}

.payment-details__type-switch-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.payment-details__input-info-icon {
    position: absolute;
    right: 10px;
    top: 11px;
    cursor: help;
}

.payment_manually {
    color: @brand;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    background: none;
    box-shadow: none;
    padding: 0;
    text-align: center;
}

.payment_manually:hover {
    cursor: pointer;
    color: @brandDark
}

.payment_automatically {
    color: @brand;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    // letter-spacing: 0.16px;
    background: none;
    border: 1px solid @brand;
    padding: 12.5px;
    border-radius: 10px;
    text-align: center;
    opacity: 1;
    transition: .2s all ease;
}

.payment_automatically:hover {
    color: @brandDark;
    opacity: .6;
}

.divider_line {
    max-width: 100%;
    min-width: 5%;
    width: 100%;
    color: @grey8;
    margin: 0%;
}

.ant-card.banklogo_card .ant-card-body {
    display: inline-table;
    width: 200px;
    text-align: center;
    height: 150px;

}

.ant-card.banklogo_card {
    box-shadow: 1px 1px @grey4;
    transition: 'all 0.3s'
}

.ant-card.banklogo_card:hover {
    border: 2px solid @brand;
}

.ant-modal.lootie_animation .ant-modal-content {
    box-shadow: none;
    background-color: transparent;
}

.bank_search {
    border-radius: 16px;
    border: 1px solid var(--icon-color, @brand);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    height: 52px;
    padding: 14px 167px 14px 14px;
    align-items: center;
    width: 360px;
    margin: 25px 0px;
}

.selectbank_heading {
    color: var(--dark-grey, #555);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 340px;
    margin-top: 45px;

}

.banklist_meta {
    padding: 12px;
    align-items: center;
}

.bankOption_list {
    display: flex;
    width: 358px;
    padding: 17px 234px 17px 16px;
    align-items: center;
    border-radius: 16px;
    background: var(--background, #F7F7F7);
    margin: 10px;
    height: 62px;
}


.searchBank_heading .profile__content-header {
    justify-content: flex-start;
}

.searchBank_heading .profile__content-heading {
    margin-left: 10px;
}


.bankOption_title {
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
}

.currency_symbol_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bankaccount_title {
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    flex-direction: column;
}

.bankOption_list .bankOption_logo {
    width: 35px;
    height: 35px;
}

.bankOption_list.selected {
    border: 2px solid @brand;
    border-block-end: 2px solid @brand !important;
    border-color: @brand;
}

.scrollable-banklist {
    max-height: 800px;
    overflow-y: auto;
    scrollbar-width: thin;
}

.scrollable-banklist::-webkit-scrollbar {
    width: 2px;
}

.scrollable-banklist::-webkit-scrollbar-thumb {
    background-color: @brand;
    border-radius: 5px;
}

.accountOption_list {
    width: 358px;

    margin: 10px;

    display: flex;
    padding: 20px 16px;
    justify-content: center;
    align-items: center;
    gap: 62px;
    border-radius: 16px;
    background: var(--background, #F7F7F7);
    box-shadow: -2px 0px 2px 0px rgba(0, 0, 0, 0.05), 2px 0px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.05), 1px 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.ant-list .ant-list-item.accountOption_list {
    padding: 12px 12px;

}

.currency_symbol {

    color: var(--dark-grey, #4A4A4A);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
}

.currency_avatar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.account_number {
    color: var(--Material-Shadow, #898A8D);
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
}

.country_avatar {
    height: 50%;
    width: 50%;
}

.check_icon {
    padding: 8px;
}

.recommended-tag {
    color: @black;
    border-radius: 20px;
    background: #FFF500;
}

.paymentmode_container {
    display: flex;
    align-items: baseline;

}

.paymentmode-title {
    color: @black;
    font-family: "Poppins";
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-align: center;
    margin-bottom: 30px;
}

.scanQR_container {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.scanQR_textcontainer {
    display: "flex"
}


.scan_QR {
    width: 100%;
    display: flex;
    justify-content: center;
}

.scan_QR canvas {
    max-width: 100%;
    height: auto;
}

.text_scancontainer.enable {

    margin-top: 30px;
    color: #555555;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    display: flex;
    justify-content: center;
}

.desktop-payment-button {
    margin-top: 30px;
    display: block;
    width: 335px;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    border: 1px solid var(--icon-color, #2EBB49);
    background: var(--White, #FFF);
    color: @brand;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
}

.payment_col {
    display: flex;
    align-items: center;
}


.paymentmode_divider {
    width: 1px;
    height: 424px;
    background: var(--stroke, #D7D7D7);
    height: 90%;

}

.accountOption_list.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.account-error {
    color: var(--red, #EC0000);
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 20px;
}

.accountOption_list.selected {
    border: 2px solid @brand;
    border-block-end: 2px solid @brand !important;
    border-color: @brand;

}

.lootie_animationModal {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50%;
}


.current_saving {
    color: var(--dark-grey, #555);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
}

#specifyColor {
    accent-color: @brandDark;
}

.divider_style {
    max-width: 100%;
    min-width: 5%;
    width: 100%;
    color: @grey8;
    margin: 10px 0;
}

.divider_txt {
    color: @grey4;
    font-family: Poppins;
    font-weight: 500;
    margin: 0%;
}



@media (max-width: 1109px) {
    .payment-details__content {
        width: 345px;
    }

    .payment-details__header {
        flex-direction: column;
        align-items: start;
    }

    .payment-details__content-body {
        margin-top: 0;
        width: 345px;
        margin-bottom: 60px;
    }

    .payment-details__form {
        flex-direction: column;
        margin-bottom: 180px;
    }

    .ant-card.banklogo_card .ant-card-body {
        display: inline-table;
        width: 160px;
        text-align: center;
        height: 150px;
    }

    .scrollable-banklist::-webkit-scrollbar {
        width: 2px;
    }

    .scrollable-banklist {
        max-height: 100%;
        overflow-y: auto;
        scrollbar-width: thin;
        color: @brand;
    }

    .banklist_meta {
        padding: 0px 6px !important
    }

    .desktop-payment-button {
        width: 174px;
    }
}

@primary-color: #2ebb49;