@import "./common/colors.less";

.reveal-password-button {
    position: absolute;
    right: 18px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;

    &:hover {
        color: #555;
    }
}

@primary-color: #2ebb49;