.swipeable-cards-container {
    margin: 0 auto;
    position: relative;
}

.swipeable-cards-wrapper {
    position: relative;
    margin: 30px auto 0px;
}

.swipeable-card {
    position: absolute;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 30px;
    box-shadow: 0px -4px 10px 0px rgba(100, 133, 249, 0.10), 0px 3px 10px 0px rgba(100, 133, 249, 0.10);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: grab;
    overflow: hidden;
    transition: opacity 0.2s ease;
}

.swipeable-card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background-color 0.1s ease;
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: center;
}

.swipeable-card-overlay::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.swipeable-card-overlay.right {
    background-color:rgba(46, 187, 73, 0.70);
}

.swipeable-card-overlay.right::before {
    background-image: url('/assets/images/new-questionnaire/card_checkmark.svg');
    background-size: 80px;
    transform: rotate(20deg);
}

.swipeable-card-overlay.left {
    background-color: rgba(236, 0, 0, 0.70)
}

.swipeable-card-overlay.left::before {
    background-image: url('/assets/images/new-questionnaire/card_close.svg');
    background-size: 80px;
    transform: rotate(-20deg);
}

.swipeable-cards-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 20px;
    gap: 48px;
}

.swipeable-card-no-button,
.swipeable-card-yes-button {
    box-sizing: border-box;
    display: flex;
    width: 66px;
    height: 66px;
    padding: 15px;
    justify-content: center;
    align-items: center;
    border-radius: 103.125px;
    background: #fff;
    box-shadow: 0px -4.125px 10.312px 0px rgba(100, 133, 249, 0.10), 0px 3.094px 10.312px 0px rgba(100, 133, 249, 0.10);
    cursor: pointer;
}

.swipeable-card-prev-button {
    box-sizing: border-box;
    display: flex;
    width: 54px;
    height: 54px;
    padding: 13px;
    justify-content: center;
    align-items: center;
    border-radius: 84.375px;
    background:  #FFF;
    box-shadow: 0px -3.375px 8.438px 0px rgba(100, 133, 249, 0.10), 0px 2.531px 8.438px 0px rgba(100, 133, 249, 0.10);
    cursor: pointer;
}

.swipeable-card-list-button {
    box-sizing: border-box;
    display: flex;
    width: 36px;
    height: 36px;
    padding: 13px;
    justify-content: center;
    align-items: center;
    border-radius: 84.375px;
    background:  #FFF;
    box-shadow: 0px -3.375px 8.438px 0px rgba(100, 133, 249, 0.10), 0px 2.531px 8.438px 0px rgba(100, 133, 249, 0.10);
    cursor: pointer;
}

.swipeable-card-buttons-text {
    margin-top: 8px;
    color: #707070;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.swipeable-card-no-select {
    user-select: none;
}

.swipeable-cards-title {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.1px;
}

.swipeable-cards-description {
    margin-top: 12px;
    color: #707070;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.swipeable-cards-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(127, 67, 255, 0.00) -8.8%, #7F43FF 99.87%);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1000;
}

.swipeable-cards-modal-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-left: 161px;
    margin-top: 438px;
}

.swipeable-cards-modal-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.swipeable-cards-modal-title {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.swipeable-cards-modal-text {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 160px;
}

.swipeable-card-title {
    color: #121212;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.swipeable-card-tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 12px
}

.expenses__swipeable-card-merchant-pill {
    background-color: #7F43FF;
    color: #FFFFFF;
    padding: 8px 16px;
    border-radius: 100px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    opacity: 0.8
}

.swipeable-card-tag {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding: 8px 16px;
    border-radius: 100px;
    background: #F3F3F3;
}

.swipeable-card.disappear-left {
    animation: disappearLeftAnimation 0.4s ease-in;
}

.swipeable-card.disappear-right {
    animation: disappearRightAnimation 0.4s ease-in;
}

.expenses__swipeable-card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.expenses__swipeable-card-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.expenses__swipeable-card-amount {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}

.expenses__swipeable-card-amount-value {
    color: #121212;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.expenses__swipeable-card-amount-transactions {
    color: #121212;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.expenses__swipeable-card-merchant {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}

.expenses__swipeable-card-description {
    color: #2EBB49;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 6px;
}

.expenses__swipeable-card-date {
    color: #707070;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
}

@keyframes disappearLeftAnimation {
    from {
        transform: translate(0, 0) rotate(0deg);
    }
    to {
        transform: translate(-400px, -100px) rotate(40deg);
    }
}

@keyframes disappearRightAnimation {
    from {
        transform: translate(0, 0) rotate(0deg);
    }
    to {
        transform: translate(400px, -100px) rotate(-40deg);
    }
}

.swipeable-card.reappear {
    animation: reappearAnimation 0.2s ease-out;
}

@keyframes reappearAnimation {
    from {
        transform: scale(0.8) translateY(50px);
        opacity: 0;
    }
    to {
        transform: scale(1) translateY(0);
        opacity: 1;
    }
}

@media (max-width: 1109px) {
    .swipeable-cards-modal-content {
        margin-left: 0;
        margin-top: 360px;
    }

    .swipeable-cards-description {
        padding: 0 20px;
    }
}

@media (max-width: 768px) {
    .swipeable-cards-container {
        width: 100%;
        margin: 0 auto;
        position: relative;
        overflow: hidden!important;
    }
}

@primary-color: #2ebb49;