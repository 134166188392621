@import "./common/colors.less";

.privacy-policy__content {
  max-width: 900px;
  width: 90dvw;
  margin: 0 auto;
}

.privacy-policy__page_title {
  font-size: 24px;
  color: @grey7;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.privacy-policy__title {
  font-size: 22px;
  color: @grey7;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.privacy-policy__subtitle {
  font-size: 18px;
  color: @grey7;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.privacy-policy__text {
  margin-bottom: 8px;
  font-size: 16px;
  color: @grey7;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  line-height: normal;
}

@media (max-width: 1109px) {
}

@primary-color: #2ebb49;