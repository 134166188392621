@import "styles/common/colors.less";

.custom-checkbox-label {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  
  .custom-checkbox-label.disabled {
    font-size: 14px;
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .custom-checkbox-input {
    display: none;
  }
  
  .custom-checkbox-box {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @grey4;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    background-color: white;
  }
  
  .custom-checkbox-box.checked {
    background-color: @primary;
    border-color: @primary;
  }
  
  .custom-checkbox-box:hover {
    border-color: @primary;
  }
  
  .custom-checkbox-checkmark {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  
  .custom-checkbox-text {
    font-size: 14px;
    color: @black;
  }
  
@primary-color: #2ebb49;