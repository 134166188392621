@import "../common/colors.less";

.documents__content {
    margin-top: 30px;
}

.document__collapse {
    margin-bottom: 20px;
    border-radius: 20px;
    background: none;
    border: none;
    border-bottom: none;
    box-shadow: none;
    overflow: auto;
    .ant-collapse-content{
        background: none;
    }
}

.document__collapse .ant-collapse-content{
    background: none;
}

.document__collapse .ant-collapse-header{
    align-items: center !important;
}

.document__collapse .ant-collapse-item{
    border-bottom: none;
}

.documents__content-item-header {
    margin-left: 30px;
    position: relative;
    height: 108px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: @grey8;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
}

.custom-chevron {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
}

.documents__item {
    padding: 14px;
}

.document__content-item-icon-wrapper {
    display: flex;
    width: 90px;
    height: 90px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: @grey1;
}

.document__content-item-icon-wrapper-filled {
    display: flex;
    width: 90px;
    height: 90px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: @brandLightFivePercent;
}

.document__content-item-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.document__content-item-header-text {
    max-width: 200px;
    margin-left: 24px;
    color: @grey7;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.08px;
}

.document__content-item-header-text-filled {
    max-width: 200px;
    margin-left: 24px;
    color: @black;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.08px;
}

.documents__item-row {
    justify-content: space-between;
}

.documents__download-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.documents__download-row {
    margin-top: 6px;
    cursor: pointer;
}

.documents__content-item-title {
    color: @grey8;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
    margin-bottom: 6px;
}

.documents__content-item-text {
    color: @grey7;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 6px;

}

.documents__content-item-date {
    color: @grey5;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.documents__download-text {
    color: #253BFF;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    line-height: 24px;
    margin-left: 3px;
}

.documents__receipts{
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
    flex-wrap: wrap;
}

.documents__upload-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.documents__upload-date {
    margin-top: 6px;
    color: @grey7;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 135px;
    text-align: center;
}

.documents__image-preview {
    cursor: help;
}

.documents__image-delete {
    position: absolute;
    padding: 3px;
    top: 0px;
    right: 0px;
    cursor: pointer;
    transition: padding 0.3s ease;
    &:hover {
        padding: 1px;
    }
}

.bottom-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 5;
}

.bottom-modal-content {
    width: 335px;
    margin: 0 auto;
}

.bottom-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    border-radius: 50px 50px 0px 0px;
    background-color: #ffffff;
    padding: 15px;
    z-index: 10;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(100%); 
    transition: transform 0.3s ease-in-out;

    &.show {
        transform: translateY(0%);
    }
}
  
  .modal-handle {
    width: 134px;
    height: 5px;
    background-color: #F7F7F7;
    margin: 0px auto 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  .modal-button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 25px;
  }
  
  .modal-button {
    display: block;
    width: 100%;
    border: none;
    text-align: left;
    padding-left: 24px;
    color: @black;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

@media (max-width: 1109px) {
    .documents__content {
        width: 345px;
        margin-top: 10px;
    }

    .documents__content-item-header {
        height: 48px;
        margin-left: 10px;
        font-size: 16px;
    }

    .document__collapse .ant-collapse-item .ant-collapse-header {
        padding: 10px;
    }

    .custom-chevron {
        left: 10px;
    }

    .documents__item {
        margin-bottom: 80px;
        padding: 4px;
    }

    .documents__item-row {
        align-items: center;
    }

    .ant-modal-content {
        padding: 10px !important;
    }

    .ant-modal-close {
        top: 5px !important;
        inset-inline-end: 5px !important;
        padding: 15px !important;
    }

    .document__content-item-icon-wrapper {
        width: 60px;
        height: 60px;
    }

    .document__content-item-icon-wrapper-filled {
        width: 60px;
        height: 60px;
    }
    .document__content-item-header-text {
        font-size: 16px;

    }
    
    .document__content-item-header-text-filled {
        font-size: 16px;
    }

    .document__collapse {
        .ant-collapse-content-box {
            padding: 16px 0 !important;
        }
    }
}
@primary-color: #2ebb49;