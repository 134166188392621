@import "../common/colors.less";

.profile-tab__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    margin-bottom: 80px;
}

.profile-tab__name {
    margin-top: 12px;
    color: @black;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.18px;
}

.profile-tab__email {
    color: @grey7;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
}

.profile-tab__menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    min-width: 351px;
}

.profile-tab__menu-group-title {
    color: @black;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.22px;
    margin-top: 24px;
}

.profile-tab__menu-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 12px;
    padding: 10px 0;
    cursor: pointer;
}

.profile-tab__menu-item div{
    display: flex;
    flex-direction: row;
}

.profile-tab__menu-item-title {
    color: @black;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.08px;
    margin-left: 12px;
}

@media (max-width: 1109px) {

}
@primary-color: #2ebb49;