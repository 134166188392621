@import "../common/colors.less";
.client__navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border-radius: 10px;
    gap: 10px;
    padding: 6px 20px;
    width: 300px;
}
.client__navbar.active {
    background: @secondaryLight;
} 
.client-navbar__connected-accounts-number {
    width: 44px;
    color: @brand;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 149.925%;
    border-radius: 100px;
    background: @brandLightFivePercent;
}
.client-navbar__bank-accounts {
    display: inline-flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 3px;
}
.client-navbar__dropdown-desktop {
    position: sticky;
    top: 93px;
    padding: 17px;
    z-index: 1000;           
    display: flex !important;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    background: @white;
    height: auto;
    width: auto;
    border-radius: 10px !important;
    background: #FFF !important;
    box-shadow: 0px -4px 10px 0px rgba(100, 133, 249, 0.10), 0px 3px 10px 0px rgba(100, 133, 249, 0.10) !important;
}
.client-navbar__dropdown-text {
    text-decoration: none;
    display: flex !important;
    color:#707070 !important;
    height: 48px;
    display: flex;
    padding-left: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 149.925%;
    align-items: center !important;
    gap: 0 !important;
    flex-direction: row;
}
.client-navbar__dropdown-text:last-child {
    border-top: 1px solid @grey0; 
    margin-top: 30px;
}
.client-navbar__dropdown-text.active {
    border-radius: 10px;
    background: @secondaryLight;
    color: @secondary !important;
    height: 48px;
}
.client-navbar__calendar {
    display: flex;
    align-items: center;
    padding: 12px;
    align-items: center;
    border-radius: 10px;
    border: 1px solid @grey4;
    justify-content: space-between;
    gap: 7px;
}
.client-navbar__dropdown-text img{
    padding-right: 10px;
}
.client-navbar__calendar-title {
    color: @black;
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    font-family: Poppins;
}
.dashboard__tax-title {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}
.dashboard__wrapper-calendar {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 16px;
}

@media (max-width: 1110px) {
    .client-navbar__overlay-menu {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: @white;
        padding: 20px;
        color: @white;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
          
    .client-navbar__dropdown-mobile {
        position: static;
        border-inline-end: none !important;
    }   
    .client-navbar__dropdown-text {
        margin: 20px 0;
        display: flex;
        align-items: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 149.925%;
        }    
    .new-questionnaire-questionnaire__calendar-title {
        color: @black;
        text-align: right;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }
    .client-navbar__calendar {
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        width: 200px;
        margin: auto;
        margin-bottom: 30px;
    }
    .logo-questionnaire-navbar {
        margin-bottom: 30px;
        margin-top: 10px;
    }
    .client-navbar__dropdown-text.active {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .client-navbar__dropdown-text:last-child {
        margin-top: 0;
        border: none;
        padding-top: 0
    }
    .client__navbar {
        background: none;
        padding: 0;
        margin: 5px;
        padding-top: 20px;
    }
    .client-navbar__border {
        border-top: @grey0 1px solid;
    }
    .client-navbar__bank-accounts {
        display: inline-flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        gap: 3px;
    }
}

@primary-color: #2ebb49;