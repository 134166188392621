@font-family: Poppins;

.price-breakdown-outer-div-desktop {
  width: 100%;
  margin: 0px auto 10px auto;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.old-price-breakdown{
  font-size: 36px;
  font-weight: 600;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  &:after{
    content: "";
    position: absolute;
    left: 5px;
    top: 50%;
    height: 1px;
    background: #7F43FF;
    width: 90%;
    display: block;
  }
}

.new-price-breakdown{
  color: #F44336
}

.price-breakdown-outer-div-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 0.5px solid #F3F3F3;
  width: 100%;
}

.price-breakdown-title {
  font-size: 36px;
  font-weight: 600;
  color: #2D2E2EBF;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  line-height: 45px;
}

.price-breakdown-subtitle {
  font-size: 18px;
  font-weight: 300;
  color: #ABABAB;
  text-align: left;
  width: 100%;
  line-height: 25px;
  margin-top: 20px;
}

.price-container {
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.why-you-need-to-pay-container {
  width: 100%;
  color: #2D2E2EBF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.common-font-style{
  font-family: @font-family;
  text-align: center;
}

.percentage-save-box{
  margin: auto;
  padding: 5px;
  background: #F44336;
  color: #FFFFFF;
  width: 94px;
  border-radius: 10px;
}

.old-price-breakdown-outer-div{
  display: flex;
  color: #7F43FF;
  background-color: #F2EBFF;
  width: 80%;
  margin: auto;
  justify-content: center;
  align-items: center;
  border-radius: var(--rounded-md, 6px);
}

.homepage__priceBreakDown-desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin: auto;
  gap: 80px;
  padding: 20px;
  border-top: white
}
.homepage__priceBreakDown-mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

.price-breakdown__box {
  position: relative;
  width: 70%;
  padding: 35px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: #2D2E2EBF;
  background-color: #f7f6fd;
  border: 2px solid #7F43FF;
  border-radius: 20px;
}

.price-breakdown__box__checkmark {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 24px;
  color: #7F43FF;
}

.price-breakdown__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
}

.price-breakdown__non-discount-amount{
  font-size: 36px;
  font-weight: 600;
  border-radius: var(--rounded-md, 6px);
}

.price-breakdown__discount-amount{
  font-size: 36px;
  font-weight: 600;
  color: #F44336;
  background-color: #FFFFFF;
}

.price-breakdown__breakdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
  font-size: 16px;
}

.price-breakdown__breakdown__section-title {
  font-weight: 600;
}

.price-breakdown__separator {
  width: 100%;
  height: 1px;
  background-color: #ABABAB;
  margin: 5px 0;
}

.price-breakdown__list-container {
  width: 100%;
}

.price-breakdown__list {
  font-size: 16px;
  font-weight: 500;
  color: #444;
  text-align: left;
  width: 100%;
}

@media (max-width: 1109px) {
  .price-breakdown__box {
    width: 100%;
    padding: 25px 0px;
    gap: 10px;
    border-radius: 10px;
  }
  .price-breakdown__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .price-breakdown__list {
    font-size: 14px;
    width: 90%;
  }
  .price-breakdown__list-container {
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .price-breakdown-title {
    color: #2D2E2E;
    font-weight: 500;
    text-align: center;
    align-items: center;
  }

  .price-breakdown-subtitle {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .price-breakdown__box__checkmark {
    top: 2px;
    right: 2px;
  }
  
}