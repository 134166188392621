.checkbox-outer-div{
  display: flex;
  width: 100%;
  margin: 20px auto 20px auto;
  gap: 10px;
  align-items: center;
}

.checkbox-checkmark {
  padding: 5px;
  height: 20px;
  width: 20px;
}

.checkbox-text {
  font-size: 16px;
  color: #444444;
  line-height: 20px;
  font-weight: 500;
  padding: 5px;
}