.alert_div{
  margin: 20px;
  padding: 30px;
  background-color:#ffcccc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.alert_icon{
  position: relative;
  justify-content: center;
  width: 100%;
  display: flex;
  margin-top: -15px;
  margin-bottom: 10px;
}

.alert_close_icon{
  position: absolute;
  top: 0;
  right: 0;
  margin-bottom: -15px;
}

.alert_text{
  width: 100%;
  position: relative;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin: 10px;
}

@media (max-width: 1110px) {
  .alert_div{
    margin: 15px;
    padding: 20px;
  }
}

@primary-color: #2ebb49;