@import "styles/common/colors.less";

.approvedRejected {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 345px;
    margin: 20px auto 10px;
}

.approvedRejected__title {
    color: @grey10;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.approvedRejected__totals-card, .approvedRejected__list-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    background: @white;
    box-shadow: 0px -4px 10px 0px rgba(100, 133, 249, 0.10), 0px 3px 10px 0px rgba(100, 133, 249, 0.10);
    width: 300px;
}

.approvedRejected__list-card-content {
    height: auto;
    margin: 5px;
    width: 100%;
}

.approvedRejected__totals-card-title {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.1px;
}

.approvedRejected__totals-card-info {
    color: #8C8C8C;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.approvedRejected__totals-card-icon {
    color: #8C8C8C!important;
}

.approvedRejected__totals-card-value {
    color: #000;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
}

.approvedRejected__list-item  {
    height: auto;
    margin: 5px;
    width: 100%;
}

.approvedRejected__checkbox--rejected {
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: red;
        border-color: red;
      }  
}

.approvedRejected__checkbox--approved {
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus+.ant-checkbox-inner {
        background-color: red !important;
        border-color: red !important;
    }
}

.approvedRejected__checkbox--rejected {
    .ant-switch-inner {
        background-color: red;
        border-color: red;
      }  
}

.approvedRejected__checkbox--approved {
    .ant-switch-inner {
        background-color: @brand;
        border-color: @brand;
      }  
}

.approvedRejected__filters {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
}

.approvedRejected__filters.sticky {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
    background: white;
    padding: 10px 5px;
    margin: auto;
}

.approvedRejected__filters__multiselect.ant-switch.ant-switch-checked {
    background-color: @primary !important; /* Background when toggled ON */
    border-color: @primary  !important;
}

.approvedRejected__collapse {
    width: 100%;
    .ant-collapse-content-box {
        padding: 0px!important;
    }
    
    .ant-collapse-header {
        display: flex;
        align-items: center!important;
        padding-left: 4px!important;
        padding-right: 4px!important;
    }
}

.approvedRejected__collapse-header {
    font-size: 14px;
    color: @grey7;
}

.approvedRejected__list-item-description, .approvedRejected__list-item-amount, .approvedRejected__action-footer-amount {
    color: @grey10;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    vertical-align: top!important;
}

.approvedRejected__action-footer-info {
    color: @grey10;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 10px 0px 6px;
}


.approvedRejected__list-item-merchant, .approvedRejected__list-item-date, .approvedRejected__action-footer-transactions {
    color: #707070;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.approvedRejected__collapse-header {
    font-size: 14px;
    color: @grey7;
}

@media(max-width: 400px) {
    .ant-drawer-content-wrapper {
        width: 100%!important; 
    
    }
}

@primary-color: #2ebb49;