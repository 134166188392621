.overlay-component-outer-div-desktop {
  font-family: Poppins, sans-serif;
  position: absolute;
  padding-top: 140px;
  bottom: 60px;
  width: 100%;
  height: 40%;
  text-align: center;
  background: linear-gradient(rgba(255,255,255, 0.2) 0%, rgba(255,255,255,1) 50%);
  top: 30%
}

.overlay-component-outer-div-mobile {
  font-family: Poppins, sans-serif;
  position: absolute;
  padding-top: 150px;
  bottom: 60px;
  width: 100%;
  height: 70%;
  text-align: center;
  background: linear-gradient(rgba(255,255,255, 0.2) 0%, rgba(255,255,255,1) 50%);
  top: 0;
}

.overlay-img{
  height: 80px;
  width: 80px;
}
