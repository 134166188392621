@import "../common/colors.less";
.ready-for-review__link {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #0666EB !important;
}
.ready-for-review__title {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
}
.ready-for-review__text {
    color: @grey11;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 6px;
}
.ready-for-review__text-link {
    display: flex;
    align-items: flex-start;
}
.ready-for-review__text-black, .ready-for-review__text-black-pdf {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
}

.ready-for-review__text-black-pdf {
    display: flex; 
    align-items: center;
    gap: 10px;
}
.ready-for-review__text-green {
    color: @brand;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
}
.ready-for-review__value {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 70%;
    margin-bottom: 12px;
}

.ready-for-review__client-details {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.ready-for-review__title .ant-collapse-content {
    border: none !important;
    background-color: transparent !important; 
  }

@media (max-width: 500px) {
    .ready-for-review__client-details {
        display: block;
    }
    .ready-for-review__title {
        font-size: 16px;
    }
}
@primary-color: #2ebb49;