@import "./common/colors.less";

.builder__form-question {
    color: @grey7;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.builder__boolean-question {
    padding: 0px;
    margin-bottom: 15px;
}

.builder__boolean-question__buttons {
    margin-top: 10px;
}

.builder__boolean-button {
    width: 160px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid @grey4;
    color: @brand;
    background-color: @white;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;

    &:hover {
        background-color: @brandDark !important;
        color: @white !important;
    }

    &:not(:last-child) {
        margin-right: 5px;
    }
}

.builder__boolean-button-selected {
    background-color: @brand;
    color: @white;

    &:hover {
        background-color: @brandDark !important;
        color: @white !important;
    }
}
@primary-color: #2ebb49;