@import "./common/colors.less";

.terms-conditions__content {
    max-width: 900px;
    width: 90dvw;
    margin: 40px auto;
}

.terms-conditions__page_title {
    font-size: 26px;
    color: @grey7;
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.terms-conditions__title {
    font-size: 22px;
    color: @grey7;
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.terms-conditions__text {
    margin-bottom: 8px;
    font-size: 16px;
    color: @grey7;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    line-height: normal;
}

@media (max-width: 1109px) {
}

@primary-color: #2ebb49;