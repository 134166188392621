@import "../common/colors.less";

.tax-summary__client-details {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}
.tax-summary__spaced-section {
    margin-top: 20px;
}

.tax-summary__bold-text {
    color: @black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 8px;
    margin-bottom: 8px;
}

.tax-summary__client-details__value {
    font-size: 16px;
    color: @grey7;
    display: flex;
    width: 50%;
    gap: 15px;
}

.tax-summary__client-details-header {
    color: @black;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}

.tax-summary__client-details-text {
    color: @black;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
    font-style: normal;
    line-height: normal;
    width: 40%;
}

.tax-summary__header {
    color: @black;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.1px;
    margin-top: 20px;
}

.tax-summary__subheading {
    color: #707070;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
}

.tax-summary__alert {
    color: @secondary;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    border-color: transparent;
    background: #EFE8FF;
    width: 100%;
}

.tax-summary__desktop-form-secondary-button {
    cursor: pointer;
    box-sizing: border-box;
    flex-direction: row;
    display: flex;
    height: 62px;
    padding: 20px 28px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #F3F3F3;
    color: @black;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    transition:
            background 0.1s ease,
            box-shadow 0.1s ease,
            transform 0.1s ease;
}

.tax-calculator__desktop-form-primary-button {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    height: 62px;
    padding: 20px 28px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: @secondary;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    transition:
            background 0.1s ease,
            box-shadow 0.1s ease,
            transform 0.1s ease;
}

.tax-summary__desktop-form-left {
    width: 120px;
    height: 100%;
}

.tax-summary__desktop-form-right {
    width: 120px;
    height: 100%;
}

.tax-summary__purple_link {
    color: @secondary;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
}

.tax-summary__blue-info {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    background: #E4EFFF;
    font-size: 14px;
    width: 720px;

}

.tax-summary__confirm-box {
    padding: 30px 0px;
    display: flex;
    gap: 10px;
    font-size: 16px
}

@media (max-width: 768px) {
    .tax-summary__client-details-text {
        width: auto;
    }
    .tax-summary__blue-info {
        width: auto;
    }
    .tax-summary__client-details__value {
        width: 100%;
    }
    .tax-summary__client-details {
        display: block;
    }
}

@primary-color: #2ebb49;