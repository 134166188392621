// ---- Width Pixels ----

.w20px {
  width: 20px ;
}

.w42px {
  width: 42px ;
}

.w50px {
  width: 50px ;
}

.w60px {
  width: 60px ;
}

.w80px {
  width: 80px ;
}

.w335px {
  width: 335px ;
}

.mWidth500 {
  max-width: 500px;
}
// ---- Width Percentages ----

.w50 {
  width: 50% ;
}

.w75 {
  width: 75%;
}

.w80 {
  width: 80%;
}

.w100 {
  width: 100% ;
}

.w50 {
  width: 50% ;
}

.w33 {
  width: 33.33% ;
}
.w90 {
  width: 90% ;
}
// ---- Height Pixels ----

.h60px {
  height: 60px ;
}

.h80px {
  height: 80px ;
}

.h50px {
  height: 50px ;
}

.h42px{
  height: 42px;
}

// ---- Height Percentages ----

.h50 {
  height: 50% ;
}

.h100 {
  height: 100% ;
}


// ---- Height VH ----

.h60vh {
  height: 60vh;
}

.h100vh {
  height: 100vh;
}

.h35px{
  height: 35px;
}

.h44px {
  height: 44px!important
}

.h340px {
  height: 340px!important;
}

.h250px {
  height: 250px!important;
}

.h375px{
  height: 375px;
}

.h625px{
  height: 625px;
}



.minh375px{
  min-height: 375px;
}

.height-fit-content{
  height: fit-content !important;
  max-height: fit-content !important;
}
