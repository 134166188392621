.summary-component-outer-div-desktop {
  position: relative;
  margin: 30px auto;
  width: 690px;
  height: auto;
  display: flex;
  padding: 30px;
  flex-direction: column;
  border-radius: 10px;
  background: var(--White, #FFF);
  box-shadow: 0px -1.818px 9.375px 0px rgba(166, 185, 255, 0.10), 0px 1.818px 9.375px 0px rgba(166, 185, 255, 0.10);
}

.summary-component-outer-div-mobile {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: var(--White, #FFF);
  border-radius: 10px;
  box-shadow: 0px -1.818px 9.375px 0px rgba(166, 185, 255, 0.10), 0px 1.818px 9.375px 0px rgba(166, 185, 255, 0.10);
  margin-bottom: 20px;
  margin-top: 20px;
  height: auto;
}

.summary-tickbox-img{
  border-radius: 50%;
  height: 32px;
  width: 32px;
  margin: auto;
}

.summary-amount {
  color: #121212;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.summary-heading{
  color: @grey10;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 100;
  line-height: 22px;
}

.summary-card-subtitle {
  color: #707070;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.detail-description {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: @lightBlue;
  margin-top: 20px;
}

.flex-space-between{
  display: flex;
  justify-content: space-between;
}

.summary-component__title{
  color: var(--black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
