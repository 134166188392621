@import "./common/colors.less";

.google-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.google-button img {
    margin-right: auto;
}

.google-button span {
    margin-right: auto;
}

.account {
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.account:hover {
    background-color: @grey1;
}

.auth__container {
    width: 1100px;
    margin: 0 auto;
}

.auth__content {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.auth__content-left {
    position: relative;
    width: 600px;
    margin-top: 264px;
    z-index: 1;
}

.auth__content-left-decorative {
    position: absolute;
    top: -240px;
    left: 87px;
    z-index: -1;
}

.auth__content-left-decorative div {
    width: 500px;
    height: 500px;
    background: radial-gradient(
        circle at center center,
        @brandFivePercent -194%,
        transparent 69%
    );
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.auth__content-left-decorative img {
    position: absolute;
    top: 120px;
    left: 170px;
    width: 283px;
    height: 200px;
}

.auth__title {
    color: @black;
    font-family: "Poppins";
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px 0px 32px 0px;
}

.auth__subtitle {
    color: @grey7;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.auth__link {
    color: @secondary;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 10px;
    &:hover {
        color: @secondaryDark;
    }
}

.auth__content-right {
    width: 400px;
    margin-top: 140px;
}

.auth__spinner {
    width: 100vw;
}

.auth__login-account-header {
    color: @black;
    text-align: center;
    font-family: "Poppins";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
}

.auth__existing-user-text {
    padding: 24px 0px;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.auth__remember-me-checkbox {
    color: @grey7;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.auth__forgot-password-text {
    color: @grey7;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.auth__reset-link {
    cursor: pointer;
    color: @secondary;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 10px;
}

.auth__row-margin-top {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.auth__start-button {
    width: 190px;
}

.auth__continue-as-guest-link {
    color: @secondary;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 10px;
    &:hover {
        color: @secondaryDark;
    }
}

.forgot-password__modal {
    .ant-modal-content {
        padding: 40px;
        .ant-modal-body {
            padding: 0px;
        }
    }
}

.forgot-password__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.reset__title {
    color: #000;
    font-family: Arial;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom: 24px;
}

.reset-button {
    width: 100%;
    height: 50px;
    color: @white;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-btn-default:disabled {
        color: #D0D5DB;
    }

}

.start-button {
    width: 131px;
    height: 50px;
    flex-shrink: 0;
    background-color: @secondary;
    color: @white !important;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

.start-button {
    width: 150px;
    background-color: @secondary;
   
    &:hover {
        background-color: lighten(@secondary, 10%);
    }
}

@media (max-width: 1109px) {
    .auth__container {
        width: 100%;
        max-width: 375px;
        margin: 0 auto;
    }

    .auth__content {
        padding: 0px 20px
    }

    .auth__content-right {
        width: 335px;
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .auth__login-account-header {
        font-size: 30px;
        text-align: center;
        margin-bottom: 12px;
    }

    .auth__existing-user-text {
        padding: 23px 0px;
        font-size: 15px;
    }

    .auth__remember-me-checkbox {
        margin-bottom: 12px;
    }

    .auth__start-button {
        width: 335px;
        margin-bottom: 12px;
    }

    .auth__continue-as-guest-link {
        display: block;
        width: 335px;
        text-align: center;
        line-height: 40px;
        margin-left: 0;
    }

    .forgot-password__modal {
        .ant-modal-content {
            padding: 20px !important;
        }
    }
}