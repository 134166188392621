@import "./common/colors.less";

.profile-tab__name-circle {
    display: flex;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: @brand;
    justify-content: center;
    align-items: center;
    color: @white;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.264px;
}

.name-circle {
    display: flex;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: @secondaryLight;
    justify-content: center;
    align-items: center;
    color: @secondary;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 4px;
}

.new-questionnaire__name-circle {
    display: flex;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: @secondaryLight;
    justify-content: center;
    align-items: center;
    color: @secondary;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.new-questionnaire__name-circle {
    display: flex;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #EFE8FF;
    justify-content: center;
    align-items: center;
    color: #7F43FF;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


@media (max-width: 1109px) {
    .name-circle {
        width: 36px;
        height: 36px;
        font-size: 15px;
    }
}
@primary-color: #2ebb49;