@import "../assets/fonts/font.css";
@import "./common/colors.less";
@import "./common/text.less";
@import "./common/tabs.less";
@import "./common/alignment.less";
@import "./common/borders.less";
@import "./common/transitions.less";
@import "./common/paddings.less";
@import "./common/margins.less";
@import "./common/buttons.less";
@import "./common/sizes.less";
@import "./paywall/checkboxComponent.less";
@import "./paywall/paymentPage.less";
@import "./paywall/priceBreakDown.less";
@import "./paywall/revolutPaymentComponent.less";
@import "./paywall/summaryComponent.less";
@import "./paywall/summaryOverlapComponent.less";
@import "./paywall/summaryTable.less";
@import "./paywall/summaryTableEntry.less";
@import "./paywall/PaymentStatusModals.less";
@import "./paywall/discountBox.less";
@import "./paywall/returnToQuestionnaire.less";

@import "./auth.less";
@import "./navigation.less";

@import "./header.less";
@import "./main.less";
@import "./footer.less";
@import "./reviews.less";
@import "./blog.less";
@import "./marketing.less";

@navigationSize: 48px;

html {
    overflow: auto;
}

.default-font() {
    font-family: "Poppins", sans-serif !important;
}

.default-font {
    .default-font()
}

body {
    .default-font();
    margin: 0;
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming)*/
    background-color: @white;
}

table {
    width: 100%;
    border-spacing: 0;
    margin: 0 auto;
}

.ant-upload {
    padding: 0 !important;
}

.scroll-block {
    overflow: auto !important;
    scrollbar-color: @grey1 transparent;
    scrollbar-width: thin;
}

.scroll-block::-webkit-scrollbar {
    height: 10px;
    width: 8px;
}

.scroll-block::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
}

.scroll-block::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-color: @grey2;
}

.common-page {
    width: 100%;
    height: calc(100dvh - @navigationSize);
}

.common-block {
    background-color: white;
    border: 1px solid rgba(112, 124, 140, 0.18);
    border-radius: 4px;
}

.spinner {
    width: 100%;
}

.lottie-container {
    position: fixed;
    padding: 5% 30%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 30%);
    z-index: 100;
}

.lottie {
    width: 150px;
    height: 150px;
}

.pointer {
    cursor: pointer;
}

.divider {
    border-color: @grey2;
}

.more-button {
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    color: @brand;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    &:hover {
        color: @brandDark;
    }
}

.email-link {
    color: @secondary !important;
    transition: color 0.3s;
    text-decoration-line: underline;
}

.email-link:hover {
    color: @brandDark !important;
}


@media (max-width: 1109px) {
    .lottie-container {
        padding: 30px 10%;
    }
}

@primary-color: #2ebb49;