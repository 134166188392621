.p4 {
  padding: 4px;
}

.p5 {
  padding: 5px;
}

.p8 {
  padding: 8px;
}

.p10 {
  padding: 10px;
}

.p16 {
  padding: 16px;
}

.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}

.p40 {
  padding: 40px;
}

.p50 {
  padding: 50px;
}


.ph10 {
  padding-left: 10px;
  padding-right: 10px;
}

.ph20 {
  padding-left: 20px;
  padding-right: 20px;
}

.ph4 {
  padding-left: 4px;
  padding-right: 4px;
}

.ph40 {
  padding-left: 40px;
  padding-right: 40px;
}

.pv2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.pv5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pv10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pv20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pv20-ph40 {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.pv10-ph20 {
  padding: 10px 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pr10 {
  padding-right: 10px;
}

.pr20 {
  padding-right: 20px;
}

.pt10 {
  padding-top: 10px;
}

.pt20 {
  padding-top: 20px;
}
