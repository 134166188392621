@import "./common/colors.less";
    .title {
        margin-left: 0px;
        text-align: center;
        color: @black;
    }
    .blog__image{
        width: 1020px;
        height: 675.75px;
        display: flex;
        margin: auto;
    }
    .blog__image_1{
        margin: auto;
        display: flex;
        width: 72px;
        height: 72px;
        border-radius: 120px;
    }
    .blog__content {
        margin: 0 auto;
    }
    .blog__body {
        margin-bottom: 8px;
        align-items: center;
        font-size: 16px;
        color: @black;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: auto;
        width: 1020px;
        img {
            display: block;
            margin: 60px auto;
            max-width: 90%;
        }
        a {
            text-decoration: underline;
            color: @black;
        }
    }
    .blog__title {
        margin-top: 34px;
        margin-bottom: 8px;
        font-size: 18px;
        color: @black;
        font-weight: 600;
        font-family: "Poppins";
        font-style: normal;
        line-height: normal;
        width: 1020px;
    }
    @media (max-width: 700px) {
    .blog__image{
        width: 360px;
        height: 232px;
        display: flex;
        margin: auto;
    }
    .blog__body {
        width: 100%;
        img {
            margin: 20px auto;
        }
    }
    .blog__title {
        width: 100%;
    }
}
