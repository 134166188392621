.auth-error__container {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border-radius: 12px;
    background: #FFDFDF;
    padding: 12px;
}

.auth-error__message {
    margin: 0px 0px 0px 16px;
    color:  #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.16px;
}

.auth-error__click-text {
    color: #253BFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.16px;
    text-decoration-line: underline;
}

@media (max-width: 1109px) {
    .auth-error__message {
        font-size: 15px;
        letter-spacing: -0.15px;
    }
    
    .auth-error__click-text {
        font-size: 15px;
        letter-spacing: -0.15px;
    }
}

@primary-color: #2ebb49;