.apple-google-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
    margin-top: 10px;
    margin: 0 10px;
    max-width: 50%;
}

@media screen and (max-width: 1110px) {
    .apple-google-button {
        max-width: 90%;
    }
}

@primary-color: #2ebb49;