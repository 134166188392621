.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.vertical-bottom {
  display: flex;
  align-items: flex-end;
}

.horizontal-center {
  display: flex;
  justify-content: center;
}

.horizontal-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.horizontal-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vertical-space-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.column-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.position-relative {
  position: relative;
}

.dblock {
  display: block;
}

.display-flex {
  display: flex;
}

.bottom-90 {
  bottom: 90px;
}

.bottom-20 {
  bottom: 20px;
}

.text-align-center {
  text-align: center;
}

.gap6 {
  gap: 6px
}

.gap10 {
  gap: 10px
}


