@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    src: local('Poppins'), url("./Poppins-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    src: local('Poppins'), url("./Poppins-Medium.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    src: local('Poppins'),url("./Poppins-SemiBold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans'),url("./OpenSans-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Akshar";
    font-style: normal;
    font-weight: 500;
    src: local('Open Sans'),url("./Akshar-Medium.ttf") format("truetype");
    font-display: swap;
}

@primary-color: #2ebb49;