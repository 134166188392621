@import "styles/common/colors.less";

.updateTransaction__content {
   margin-bottom: 65px;
}

.updateTransaction__action-buttons {
    position: fixed;
    bottom: 0;
    display: flex;
    width: 335px;
    justify-content: space-between;
    padding-bottom: 20px;   
    padding-top: 10px;
    background-color: @white;
}

.updateTransaction__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.updateTransaction__list-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border: 1px solid @grey2;
    border-radius: 10px
}
@primary-color: #2ebb49;