.return-to-questionnaire__container {
    display: flex;
    width: 100%;
    min-height: 102px;
    padding: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #7F43FF;
    background: #EFE8FF;
    box-shadow: 0px -1.818px 9.375px 0px rgba(166, 185, 255, 0.10), 0px 1.818px 9.375px 0px rgba(166, 185, 255, 0.10);
}

.return-to-questionnaire__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.return-to-questionnaire__title {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1px;
}

.return-to-questionnaire__text {
    color: #444;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.return-to-questionnaire__button {
    cursor: pointer;
    display: flex;
    padding: 12px 38.5px 12px 36.5px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #7F43FF;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    &:hover {
      box-shadow: 0 0 8px 0 #e4d7ff, 0 0 4px #7F43FF;
    }
}

@media (max-width: 1109px) {
    .return-to-questionnaire__container {
        width: 95%;
        margin: 0 auto;
        padding: 10px;
    }

    .return-to-questionnaire__text {
        font-size: 15px;
    }
}