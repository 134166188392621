@import "./common/colors.less";

@lineThrough: #FFF500;
@tablePrimary: #4C0A82;
@tablePrimaryNew: @secondary;
@tablePrimaryLight: #F5E9FF;
@tablePrimaryBorder: #F0E0FD;
@bannerPrimary: #22562C;

.self-employed__container-desktop {
    padding: 64px 100px;
    overflow: hidden;
}
.self-employed__title {
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 60px;
}
.self_employed__subtitle_purple {
    text-decoration-line: underline;
    color: @secondary;
    cursor: pointer;
}

.self-employed__subtitle {
    color: @grey11;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.self-employed__text {
    color: @grey12;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}

.self-employed__description{
    color: @grey10;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}
.self-employed__white-box {
    padding: 80px 160px;
    border-radius: 20px;
    border: 1px solid @white;
    background: @white;
    width: 600px;
    text-align: center;
    margin: auto;
}

.purple-link {
    color: @secondary;
}

.uk-banner-text-new {
    color: @grey8;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.uk-connect-image {
    width: 60px;
    height: 60px;
    padding: 10px 20px 0px 0px;
 }

.logo {
    cursor: pointer;
    color: green;
    height: 30px;
}

.logo-questionnaire {
    cursor: pointer;
    color: green;
    height: 30px;
}

.layout {
    width: 100%;
    margin: 0 auto;
    background: none;
}

.uk-sub-slogan-purple {
    color: @tablePrimaryNew;
    font-family: Poppins;
    font-weight: 800;
    line-height: 72px;
    font-size: 80px;

}
.uk-sub-slogan-green {
    color: @brand;
    font-family: Poppins;
    font-weight: 800;
    line-height: 72px;
    font-size: 80px;
}
.uk-text-line-through, .uk-slogan-line-through, .uk-sub-slogan-line-through, .uk-slogan-description-line-through {
    position: relative;
    text-align: center;
    z-index: 1;
    white-space: nowrap;
}

.uk-line-through-before() {
    border-radius: 10px;
    content: "";
    width: 95%;
    z-index: -1;
    position: absolute;
}

.uk-slogan-line-through:after {
    border: 14px solid @lineThrough;
    .uk-line-through-before();
    margin-top: 134px;
    top: -100px;
    left: -6px;
    bottom: 20px;
}

.uk-sub-slogan-line-through:after {
    border: 14px solid @lineThrough;
    .uk-line-through-before();
    margin-top: 134px;
    top: -110px;
    left: -10px;
}

.uk-slogan-description-line-through:after {
    border: 8px solid @lineThrough;
    .uk-line-through-before();
    margin-top: 132px;
    top: -122px;
    left: -6px;
}

.uk-text-line-through:after {
    border: 6px solid @lineThrough;
    border-radius: 10px;
    content:"";
    margin-top: 132px;
    position: absolute;
    top: -122px;
    left: -6px;
    width: 95%;
    z-index: -1;
}
.uk-flex-right {
    flex: 1;
    text-align: right;
}

.uk-flex-left {
    flex: 1;
    text-align: left;
    margin-bottom: 0;
}

.uk-middle-line-container {
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 20px;
}

.uk-horizontal-container {
    display: flex;
    justify-content: space-between;
}
.uk-code-slogan, .uk-code-slogan-header {
    color: #0C0B0A;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.24px;
    margin-bottom: 15px;
}
.uk-code-slogan-header {
    font-size: 28px;
    font-weight: 900;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.uk-code-description, .uk-code-description-desktop {
    color: var(--Beayou-Color-System-Text-Secondary-text, @grey8);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.08px;
    margin-bottom: 15px;
}

.uk-code-description-desktop {
    text-align: left;
}

.uk-code-description-text{
    display: flex;
    flex-direction: column;
    color: var(--dark-gray, #555);
    font-size: 15px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    margin-bottom: 20px;
}
.uk-slogan {
    font-size: 60px;
    font-style: normal;
    font-weight: 800;
    z-index: 2 !important;
}

.uk-slogan-new {
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    z-index: 2 !important;
    line-height: 72px;
    font-family: Poppins;
}

.uk-sub-slogan, .uk-sub-slogan-bank {
    color: #0C0B0A;
    font-size: 48px;
    font-weight: 800;
    font-family: Poppins;
    line-height: 60px;
}

.uk-slogan-description {
    color: #0C0B0A;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    font-family: Poppins;
}
.uk-slogan-block {
    max-width: 800px;
    z-index: 2 !important;
}
.uk-slogan-block-new {
    max-width: 670px;
    z-index: 2 !important;
}

.uk-slogan-description-block {
    margin-top: 40px;
    max-width: 400px;
}

.container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
    overflow: hidden;
  }

  .text-container {
    margin-right: 20px;
  }

  .text-container p {
    margin: 0;
    font-size: 16px;
    max-width: 140px;
  }

  .image-container {
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    max-width: 100%;
  }

  .overlay-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    border: 0.5px solid white;
  }

  .image1 {
    left: 0;
    z-index: 1;
  }

  .image2 {
    left: 50px;
    z-index: 2;
  }

  .image3 {
    left: 100px;
    z-index: 3;
  }

  .symbol {
    left: 150px;
    z-index: 4;
  }
  .uk-main-image {
    background: url("../assets/images/home/constructors.png") no-repeat 411px 119px;
    background-size: 825px;
    z-index: 3 !important;
}
  .uk-main-image-new {
    background-size: 480px, 170px, 190px;
    z-index: 3 !important;
}
 .uk-recommended {
    background-color: yellow;
    border-radius: 20px;
    color: black;
    padding: 5px 10px;
    font-size: 14px;
    align-items: center;
    display: flex;
    margin-left: 15px;
    font-weight: 700;
    line-height: 23px;
  }

.uk-main-image-mobile {
    max-width: 100%;
    height: 320px;
    object-fit: contain;
    z-index: 3 !important;
}
.uk-rounded-button-start {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    max-width: 220px;
    padding-right: 56px !important;
    text-align: start;
}
.uk-rounded-button-start-new {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    height: 48px;
    max-width: 260px;
    padding-right: 56px !important;
    text-align: center;
    background-color: #000;
    width: 170px;
}


.uk-rounded-button-login {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 700;
    height: 36px;
    min-width: 100px;
    line-height: 20px;
    padding-right: 56px !important;
    text-align: start;
    padding: 8px 16px !important;
    background-color: @secondary;
}

.purple {
    background: @tablePrimary !important;
}

.uk-rounded-button-start-arrow {
    cursor: pointer;
    background-color: white;
    min-height: 42px;
    min-width: 42px;
    height: 42px;
    width: 42px !important;
    border-radius: 40px !important;
    position: relative;
    left: -46px;
    z-index: 2 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @primary;
}

.uk-rounded-button-start-arrow-new {
    cursor: pointer;
    min-height: 42px;
    min-width: 42px;
    height: 42px;
    width: 42px !important;
    border-radius: 40px !important;
    position: relative;
    left: -46px;
    z-index: 2 !important;
    display: flex;
    align-items: center;
    color: @primary;
}

.uk-rounded-button-login-arrow {
    cursor: pointer;
    background-color: white;
    min-height: 42px;
    min-width: 42px;
    height: 42px;
    width: 42px;
    border-radius: 40px !important;
    position: relative;
    left: -46px;
    z-index: 2 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @primary;
}
.uk-main-block {
    padding-top: 96px;
    background-color: #F7F7F7;
    padding-bottom: 4px;
    z-index: 4 !important;
}
.uk-main-block-new {
    padding-top: 50px;
    padding-bottom: 4px;
    z-index: 4 !important;
}

.uk-how-it-works-block {
    background-color: white;
}
.uk-faqs-block {
    background-color: @grey1;
}

.uk-how-it-works-title {
    color: #0C0B0A;
    font-size: 28px;
    font-weight: 700;
}

.uk-why-taxzap-description {
    color: #0C0B0A;
    font-size: 20px;
    font-weight: 400;
    font-family: Poppins;
    line-height: 28px;
}

.uk-wt-image {
    border-radius: 10px;
    width: 100%;
    max-height: 550px;
    object-fit: contain;
}
.uk-wt-image-new {
    border-radius: 10px;
    width: 100%;
    max-height: 575px;
    object-fit: contain;
}

.uk-wt-video {
    border-radius: 10px;
    max-height: 512px;
    object-fit: cover;
}
.uk-wt-video-new {
    border-radius: 10px;
    max-height: 575px;
    object-fit: cover;
    width: 406px;
}
.uk-why-taxzap-container-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
}
.uk-why-taxzap-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}
.uk-why-taxzap-table-header {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: @tablePrimary;
}
.uk-why-taxzap-table-header-new {
    border-radius: 8px;
    background: @grey0;
    display: flex;
    height: 66px;
    align-items: center;
    cursor: pointer;
}

.uk-why-taxzap-table-header-label,
.uk-why-taxzap-table-header-label-grey{
    font-weight: 500;
    font-size: 18px;
    display: flex;
    height: 54px;
    padding: 0 12px;
    justify-content: space-evenly;
    align-items: center;
    width: 330px;
    font-family: Poppins;
    color: @grey13;
}
.uk-why-taxzap-table-header-label.active,
.uk-why-taxzap-table-header-label-grey.active{
  background-color: white;
  color: @black;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

}

.uk-why-taxzap-table-header-label-grey{
    color: @grey13;
}


.uk-why-taxzap-table-row:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.uk-why-taxzap-table-row {
    width: 950px;
}
.uk-why-taxzap-table-row-label {
    color: @grey8;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.uk-faqs-title {
    color: @black;
    font-size: 20.18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}

.uk-faqs-collapse {
    .ant-collapse-item {
        border-bottom: 1px solid @grey2;
        .ant-collapse-header {
            padding: 16px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
    .ant-collapse-content-box {
        padding: 0 !important;
    }
}

.uk-faqs-collapse-new {
    .ant-collapse-item {
        border-bottom: 1px solid @grey2;
        .ant-collapse-header {
            padding: 16px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
    .ant-collapse-content-box {
        padding: 0 !important;
    }
}

.uk-signup-wrapper {
    position: absolute;
    top:75px;
    right: 75px;
}

.uk-booking-page-button {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--primary, #2EBB49);
    text-decoration: underline;
    text-underline-offset: 3px;
}
.uk-sign-up-desktop {
    position: relative;
    width: 400px;
    height: 524px;
    background-color: @white;
    border-radius: 9px;
    box-shadow: 0 0 3.006px 0 rgba(0, 0, 0, 0.16);
}

.uk-sign-up-desktop-new {
    position: relative;
    min-height: 480px;
    width: 400px;
    background-color: @white;
    border-radius: 9px;
    box-shadow: 0 0 3.006px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 10px #6485f91a, 0 -4px 10px #6485f91a;
    padding: 32px;
    border-radius: 30px;
}

.uk-sign-up-mobile {
    margin: 70px auto 0 auto;
    padding-top: 15px;
    max-height: 95vh;
    width: 400px;
    overflow: auto;
    background-color: @white;
}
.uk-sign-up-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    background: @tablePrimary;
    max-height: 62px;
    scroll-margin-top: 150px;
}

.uk-sign-up-header-wrapper-new {
    display: flex;
    align-items: center;
    padding: 0 16px 16px;
    margin: 0 auto;
    background: @white;
}
.uk-sign-up-header {
    width: 364px;
    padding: 18px 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.uk-sign-up-header-new {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.uk-sign-up-header-title {
    color: @white;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.65px;
}

.uk-sign-up-header-title-new {
    color: @black;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.65px;
    width: 100%;
}

.uk-sign-up-content {
    display: flex;
    flex-direction: column;
    padding: 0 36px;
    padding-top: 18px;
    height: 362px;
    padding-bottom: 100px;
    overflow: scroll;
}
.uk-sign-up-content-new {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    max-height: 450px;
    height: 450px;
    overflow: auto;
}

.uk-sign-up-footer {
    width: 400px;
    margin-top: 16px;
    position: sticky;
    bottom: 0;
    background-color: @white;
}
.uk-sign-up-help-text {
    color: @black;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 14px;
}

.uk-sign-up-help-text-new {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 10px 0 24px;
}
.uk-sign-up-back-button {
    cursor: pointer;
    padding: 2px 0 2px 20px;
    color: @white;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.uk-sign-up-back-button-new {
    cursor: pointer;
    padding: 2px 0 2px 20px;
    color: @grey6;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 5px
}

.uk-sign-up-next-button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 328px;
    height: 68px;
    background: @brand !important;
    color: @white !important;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.uk-sign-up-next-button-new {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 328px;
    height: 68px;
    background: @secondary !important;
    color: @white !important;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: auto;

    &:disabled {
        opacity: 0.5;
    }
}

.uk-sign-up-next-button-new {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 335px;
    height: 62px;
    background: @secondary !important;
    color: @white !important;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: auto;

    &:disabled {
        opacity: 0.5;
    }
}

.uk-sign-up-question-text {
    color: @grey8;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 12px;
}
.uk-submission-banner {
    border-radius: 40px;
    background: #B3E693;
    display: flex;
    max-width: 1000px;
    height: 423px;
    padding: 31.5px 100px;
    align-items: center;
    gap: 20px;
    flex-grow: 1;
}

.uk-submission-banner-new {
    border-radius: 40px;
    display: flex;
    max-width: 1000px;
    height: 423px;
    padding: 31.5px 100px;
    align-items: center;
    gap: 200px;
    padding: 20px 0;
}

.submission-fee-description{
    color: "black";
    font-size: "48px";
}

.uk-taxzap-blog {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: white;
    padding: 60px 0px 70px;
}

.uk-blog-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600;
    line-height: normal;
    color: @black;
    padding: 0px 20px 16px 0px;
    font-family: Poppins;
    margin-bottom: 0px !important;
    font-size: 18px;
    color: var(--black, #000);
    // width: 406px;
    &:hover{
        text-decoration: underline;
     }
}
.uk-blog-slogan-green, .uk-blog-slogan-black {
    color: #0C0B0A;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 34px;
    font-family: Poppins;
}

.uk-blog-slogan-green {
    color: var(--primary, #2EBB49);
}

.uk-blog-image {
    max-width: 335px;
    // min-height: 269px;
    margin-bottom: 24px;
    border-radius: 10px 10px 0px 0px;
}

.uk-blog-description {
    align-items: center;
}

.uk-blog-author-image{
    border-radius: 120px;
    width: 42px;
    height: 42px;
}

.uk-blog-author-subtext{
    color: #949494;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.uk-blog-author-text {
    color: @grey8;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: -20px;
    margin-left: 7.5px;
    width: 200px;
    margin-bottom: 5px;
}

.uk-how-it-works__grey_bubble {
    display: flex;
    height: 78px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: @grey0;
    cursor: pointer;
}

.uk-how-it-works__grey_bubble:hover {
    background: @grey4;
}

.uk-how-it-works__description {
    color: @grey10;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    width: 365px;
    margin-top: 30px;
}

 .uk-how-it-works__type {
    color: @black;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    text-decoration: none;;
 }

.uk-how-it-works__black-box {
    width: 406px;
    height: 252px;
    border-radius: 20px;
    margin-top: 30px;
}

.uk-how-it-works__green-box, .uk-how-it-works__purple-box {
    width: 859px;
    height: 490px;
    border-radius: 20px;
    margin-bottom: 40px;
    object-fit: contain;
 }

 .uk-how-it-works__banner {
    display: flex;
    gap: 47px;
}

 .uk-how-it-works__title {
    color: @black;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 60px;
    font-family: Poppins;
 }

.how-it-works-container-desktop {
    position: relative;
    height: 1100px;
    width: 100%;
    padding: 64px 100px;
}

.uk-how-it-works__black-box-position-one {
    position: absolute;
    color: white;
    top: 25%;
    left: 40px;
}
.uk-how-it-works__black-box-position-two {
    position: absolute;
    color: white;
    width: 275px;
    top: 40%;
    left: 40px;
}
.uk-how-it-works__purple-box-position {
    position: absolute;
    top: 40px;
    right: 10%;
    left: 30px;
}
.uk-how-it-works__green-box-position {
    position: absolute;
    top: 20%;
    left: 56%;
}

.uk-how-it-works__position {
    position: relative;
    display: inline-block;
}

@media screen and (max-width: 820px) {
    .uk-slogan-description {
        font-size: 16px;
    }
    .uk-why-taxzap-table-row {
        width: auto;
    }
    .uk-why-taxzap-container-mobile {
        justify-content: flex-start;
        margin-bottom: 24px;
    }
    .uk-why-taxzap-table-header-label,
    .uk-why-taxzap-table-header-label-grey {
        width: 137px;
    }
    .uk-why-taxzap-table-row-label {
        font-size: 16px;
    }
    .self-employed__text {
        font-size: 16px;
        text-align: center;
    }
    .self-employed__container-mobile {
        padding: 24px;
        overflow: hidden;
    }

    .self-employed__title {
        font-size: 28px;
        line-height: 34px;
    }

    .self-employed__subtitle {
        font-size: 17px;
        line-height: 23px;
        margin-bottom: 32px;
    }

    .self-employed__white-box{
        width: 100%;
        height: 220px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
    }

    .uk-why-taxzap-description, .uk-how-it-works-description {
        font-size: 17px;
    }
    .overlay-image {
        width: 60px;
        height: 60px;
      }
    .uk-connect-image {
        width: 48px;
        height: 48px;
        padding: 10px 12px 0px 0px;
     }

     .uk-submission-banner {
        border-radius: 0;
    }

    .uk-submission-banner-new {
        border-radius: 0;
        gap: 0px;
    }
    .uk-blog-image {
        max-width: 320px;
        margin-bottom: 24px;
        border-radius: 10px 10px 0px 0px;
    }
    .uk-blog-slogan-green, .uk-blog-slogan-black {
        font-size: 30px;
    }
    .uk-blog-content {
        padding: 0px 20px 16px 5px;
        width: 320px;
    }
    .uk-booking-page-button {
        font-size: 14px;
    }
    .uk-sub-slogan-purple, .uk-sub-slogan-green {
        font-size: 42px;
        line-height: 40px;
    }

    .uk-sub-slogan {
        line-height: 40px;
    }
    .uk-sub-slogan-bank {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        display: flex;
        align-items: center;
    }
    .submission-fee-description {
        border-radius: var(--radi-lg, 12px);
        background: #F1EAFF;
        width: 342px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: @secondary !important;
        margin-bottom: 40px;
        margin-top: 10px;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.36px;
        height: 60px;
    }
    .uk-how-it-works__banner {
        display: block;
    }
    .uk-how-it-works__green-box, .uk-how-it-works__purple-box {
        width: 342px;
        height: 490px;
        border-radius: 20px;
        margin: auto;;
        margin-bottom: 40px;
    }

    .uk-how-it-works__title{
        font-size: 26px;
        line-height: 34px;
    }
    .uk-how-it-works__type {
        font-size: 16px;
        line-height: 18px;
    }
    .uk-how-it-works__grey_bubble {
        height: 60px;
        width: 342px;
    }
    .uk-how-it-works__black-box {
        width: 342px;
        margin-bottom: 40px;
    }
    .uk-how-it-works__description {
        width: 100%;
        margin-top: 20px;
    }
    .how-it-works-container-mobile {
        height: auto;
        padding: 24px;
        overflow: hidden;
    }
    .uk-how-it-works__boxes-position {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .uk-how-it-works__position {
        display: flex;
        align-items: center;
       justify-content: center;
       align-items: center;
    }
    .uk-how-it-works__black-box-position-one {
        font-size: 20px;
        font-weight: 400;
        line-height: 25.271px;
    }
    .uk-how-it-works__black-box-position-two {
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 50.542px;
        width: 230px;;
    }
    .uk-how-it-works__purple-box-position {
        padding: 20px;
        position: absolute;
        top: 0%;
        left: 0%;
    }
    .uk-how-it-works__green-box-position {
        padding: 20px;
        position: absolute;
        top: 0%;
        left: 0%;
    }
}

.uk-submission-banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.uk-banner-title {
    color: @bannerPrimary;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.uk-banner-text {
    color: @bannerPrimary;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.uk-banner-text-new {
    font-size: 16px;
}

.uk-cookie__button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px!important;

}
.uk-cookie__button--accept {
    font-family: Poppins;
    font-size: 14px;
    border-radius: 50px;
    padding: 10px 30px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
    background-color: @black;
    color: @white;
    cursor: pointer;
}

.uk-cookie__button--decline {
    font-family: Poppins;
    font-size: 14px;
    border-radius: 50px;
    padding: 10px 30px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
    background-color: @white;
    color: @black;
    border: 1px solid #000;
    cursor: pointer;
}

@media (max-width: 1415px) {
    .uk-main-image-new {
        background:
        url("../assets/images/home/header-image.png") no-repeat 471px 220px,
        url("../assets/images/home/start-application.svg") no-repeat 700px 70px,
        url("../assets/images/home/green-star.svg") no-repeat 1255px 545px;
        background-size: 440px, 150px, 150px;
        z-index: 3 !important;
    }
    .uk-slogan, .uk-sub-slogan-green, .uk-sub-slogan-purple {
        font-size: 42px;
        font-weight: 800;
        line-height: 40px
    }
    .uk-slogan-new {
        font-size: 42px;
        font-weight: 800;
        line-height: 40px
    }
}

@media (max-width: 1200px) {
    .uk-wt-video {
        max-height: 581px;
    }
    .uk-slogan-block {
        max-width: 500px;
    }

    .uk-slogan, .uk-sub-slogan-green, .uk-sub-slogan-purple {
        font-size: 42px;
        font-weight: 800;
        line-height: 40px;
    }
    .uk-slogan-new {
        font-size: 42px;
        font-weight: 800;
        line-height: 40px;
    }
    .uk-slogan-line-through:after {
        margin-top: 119px;
    }
}

@media (max-width: 1109px) {
    .uk-sign-up-footer {
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-bottom: 10px;
        background-color: @white;
        padding: 20px 0;
    }

    .uk-sign-up-header-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        border-radius: 0;
        width: 100%;
        z-index: 1;
    }

    .uk-sign-up-content-new {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
    }

    .uk-sign-up-content{
        padding: 0px 23px;
        padding-top: 82px;
        height: auto;
    }
    .uk-submission-banner {
        height: auto;
        flex-direction: column;
        width: 390px;
        max-width: 390px;
        padding: 32px 24px;
    }
    .uk-submission-banner-new {
        height: auto;
        flex-direction: column;
        width: 390px;
        max-width: 390px;
        padding: 32px 24px;
        gap: 0px;
    }

    .uk-banner-title {
        font-size: 32px;
    }

    .uk-banner-text {
        font-size: 16px;
    }

    .uk-sub-slogan {
        font-size: 30px;
    }

    .uk-sub-slogan-line-through:after {
        border: 10px solid #FFF500;
        border-radius: 10px;
        margin-top: 116px;
        top: -104px;
        left: -5px;
    }

    .uk-main-block {
        padding-top: 52px;
    }

    .uk-slogan-description-block {
        margin-top: 15px;
    }

    .uk-rounded-button-login-arrow {
        min-height: 30px;
        min-width: 30px;
        height: 30px;
        width: 30px;
        left: -34px;
    }

    .uk-sign-up-mobile {
        width: 100%;
        max-width: 375px;
    }

    .uk-sign-up-header  {
        padding: 18px 24px;
    }

    .logo-questionnaire {
        height: 24px;
    }
}

@media (max-width: 375px) {
    .overlay-image {
        width: 50px;
        height: 50px;
    }

    .image1 {
        left: 0;
        z-index: 1;
    }

    .image2 {
        left: 30px;
        z-index: 2;
    }

    .image3 {
        left: 60px;
        z-index: 3;
    }

    .symbol {
        left: 80px;
        z-index: 4;
    }

    .how-it-works-container-mobile {
        height: auto;
        padding: 0px;
        overflow: hidden;
    }

    .uk-sign-up-content-new {
        height: 80%;
        min-height: 80%;
        max-height: 80%;
    }

    .uk-why-taxzap-table-header-new {
        width: 342px;
    }

    .uk-whytaxzap-taxzap-container-mobile{
        width: 350px;
    }

    .uk-why-taxzap-table-header-label,
    .uk-why-taxzap-table-header-label-grey {
        width: 136px;
    }

    .logo {
        height: 24px;
    }
  }
