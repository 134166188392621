@import "../common/colors.less";
.bold {
  font-weight: 600
}

.extra-bold {
  font-weight: 700
}

.underline {
  text-decoration: underline;
}

.no-decoration {
  text-decoration: none;
}

.text-align-center {
  text-align: center;
}

.word-break-all {
  word-break: break-all;
}

.pre-wrap {
  white-space: pre-wrap;
}

.text-shadow {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.28);
}

.input-label {
  font-weight: 700;
  font-size: 16px;
}

.input-sub-label {
  font-weight: 700;
  font-size: 14px;
}

.input-description {
  font-weight: 500;
  font-size: 13px;
}

.input-content {
  font-weight: 600;
  font-size: 16px;
}

.select-content {
  font-weight: 600;
  font-size: 16px;
}

.select-option {
  font-weight: 600;
  font-size: 16px;
}

.modal-header {
  font-weight: 600;
  font-size: 18px;
}

.modal-description {
  font-weight: 600;
  font-size: 16px;
}

.table-header {
  font-weight: 600;
  font-size: 17px;
}

.table-content {
  font-weight: 600;
  font-size: 15px;
}

.checkbox-label {
  font-weight: 600;
  font-size: 16px;
}

.pipeline-card-status-time {
  font-weight: 600;
  font-size: 12px;
}

.h1 {
  font-weight: 700;
  font-size: 28px;
}

.h2 {
  font-weight: 700;
  font-size: 20px;
}

.h3 {
  font-weight: 600;
  font-size: 18px;
}

.h4 {
  font-weight: 600;
  font-size: 16px;
}

.h5 {
  font-weight: 400;
  font-size: 14px;
}

.h6 {
  font-weight: 400;
  font-size: 12px;
}

.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
}

.fs10{
  font-size: 10px;
}

.fs11 {
  font-size: 11px;
}

.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
}

.fs22 {
  font-size: 22px;
}

.fs24 {
  font-size: 24px;
}

.fs30 {
  font-size: 30px;
}

.fs36 {
  font-size: 36px;
}

.fs40 {
  font-size: 40px;
}

.fw100 {
  font-weight: 100 !important;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fc444 {
  color: #444
}

.fc000000 {
  color: #000000
}

.fcF44336 {
  color: #F44336
}

.fc737373{
  color: #737373;
}

.taLeft{
  text-align: left!important;
}

.taRight{
  text-align: right!important;
}

.taCenter{
  text-align: center!important;
}

.poppins {
  font-family: "Poppins";
}

.fcBrand {
  color: @brand !important;
}
.mWidth500 {
  max-width: 500px;
}

.lineHeight20 {
  line-height: 20px;
}
