@import "./common/colors.less";

.small-status__dot {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    margin-right: 6px;
}

.small-status__big-dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 6px;
}

.small-status__dot-pending {
    background-color: @red;
}

.small-status__dot-active {
    background-color: #FD7900;
}

.small-status__dot-done {
    background-color: @brand;
}

.small-status__text {
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    text-transform: capitalize;
}

.small-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.small-status__active {
    background: #FFF8ED;
    color: #FD7900;
}

.small-status__done {
    background: #EAF8ED;
    color: @brand;
}

.small-status__pending {
    background: #F8EAEA;
    color: @red;
}

@media (max-width: 1109px) {
    .small-status__text {
        font-size: 14px;
    }

    .small-status {
        padding: 5px 10px;
    }
}
@primary-color: #2ebb49;