@import "./common/colors.less";

.button-footer, .button-footer-marketing  {
    z-index: 3;
    display: flex;
    flex-direction: row;
    position: fixed;
    min-height: 84px;
    width: 100dvw;
    bottom: -100px;
    left: 0;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: linear-gradient(132deg, rgba(255, 255, 255, 0.60) -3%, rgba(255, 255, 255, 0.60) 113.86%);
    backdrop-filter: blur(2.955664873123169px);
    transition: bottom 0.3s ease;
}

.button-footer.visible, .button-footer-marketing {
    bottom: 0;
}

.personal-info-button {
    position: inherit !important;
    width: auto;
}

.profile-button-footer {
    display: flex;
    position: fixed;
    min-height: 66px;
    width: 100dvw;
    background-color: @white;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.profile-button-footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 375px;
    min-height: 66px;
    padding: 20px 0;
}

.button-footer-or {
    color: @black;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: -23px;
}

.button-footer-start-button,
.button-footer-blog-button {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 335px;
    min-width: 120px;
    padding: 0 30px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    height: 50px;
    border-radius: 10px;
    background: @brand;
    color: @white !important;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    border: none;
    margin: 0 auto;

    &:hover {
        background: @brandDark;
        border: none;
        box-shadow: none;
    }

    &:disabled {
        color: @grey3;
        background: @grey1;
        cursor: not-allowed;
    }
}

.button-footer-blog-button {
    background: #540087;
    width: 90dvw;

    &:hover {
        background: #3E0064;
        border: none;
        box-shadow: none;
    }
}

.footer-auth__subtitle {
    color: @grey7;
    text-align: center;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.footer-start__link {
    color: @brand;
    text-align: center;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 20px;
}

.questionnaire-button-footer-v2 {
    display: flex;
    position: fixed;
    min-height: 66px;
    width: 100dvw;
    background-color: @white;
    bottom: 0px;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 5;
    border-top: 1px solid #F4F4F4;
}

.questionnaire-button-footer-v2--desktop {
    display: flex;
    position: fixed;
    min-height: 66px;
    width: calc(100%);
    bottom: 0px;
    left: 0px;
    // margin-left: 162px;
    align-items: center;
    justify-content: center;
    z-index: 5;
    background: linear-gradient(to right, transparent 0%, white 33%, white 66%, transparent 100%);
}

.questionnaire-button-footer-content-v2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 375px;
    min-height: 66px;
}
@primary-color: #2ebb49;