@import "../common/colors.less";

.discount-outer-container{
  margin: 0 auto;
  height: 100%;
  width: 100%;
  padding: 0;
}

.apply-promo-button-outer-div-desktop{
  width: 80%;
  margin: 0 auto;
  display: flex;
}

.apply-promo-button-outer-div-mobile{
  width: 90%;
  margin: 0 auto;
  display: flex;
}

.apply-promo-button-inner-div{
  width: 100%;
  justify-content: left;
  padding: 0;
}

.apply-promo-button{
  width: 100%;
  background: transparent;
  text-align: left;
  border: none;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-promo-button-inner-div{
  width: 30%;
  text-align: right;
  padding-right: 5px;
}

.close-promo-button{
  background: white;
  text-align: right;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.discount-input-box{
  outline: none;
  margin: 5px 5px 5px 0;
  border-radius: 10px;
  border: 1px solid transparent;
  background: var(--Background-background, #FFF);
  height: 40px;
  width: 70%;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
}

.discount-input-box::placeholder{
  color: #DFDFE7;
}

.discount-input-box-error{
  border: 1px solid #DD1D1D;
  background: var(--Background-background, #FFF);
}

.discount-input-box-success{
  border: 1px solid #2EBB49;
  background: var(--Background-background, #FFF);
}

.invalid-code-box{
  color: #DD1D1D;
  text-align: left;
  margin: auto;
  font-size: 14px;
}

.discount-applied-desktop{
  border: none;
  background: var(--Background-background, #FFF);
  color: #2EBB49;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  width: 100px;
}

.discount-applied-mobile{
  border: none;
  background: var(--Background-background, #FFF);
  color: #2EBB49;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  width: 80px;
}

.discount-applied-image-desktop{
  position: relative;
  top: 4px;
  height: 20px;
  width: 20px;
  margin-top: 5px;
  margin-left: 5px;
}

.discount-applied-image-mobile{
  position: relative;
  top: 3px;
  height: 15px;
  width: 15px;
  margin-top: 5px;
  margin-left: 5px;
}

.discount-input-outer-div {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.discount-input-inner-div {
  position: relative;
}

.discount-icon {
  color:#2EBB49;
  position: absolute;
  right: 50px;
  top: 15px;
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: middle;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}