@import '../common/colors.less';

iframe {
  position: relative;
  bottom: 7px;
  height: 100% !important;
}

.revolut-payment-outer-container-desktop {
  padding: 0;
  height: 60vh;
  width: 100%;
  position: relative;
}

.revolut-payment-outer-container-mobile {
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 100px;
}

.revolut-inner-container-header-desktop {
  position: relative;
  background-color: white;
  width: 100%;
  height: 60px;
  margin: 0;
  padding-top: 5px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.revolut-inner-container-header-mobile {
  position: relative;
  background-color: white;
  margin: 0;
  padding-top: 5px;
  width: 100%;
  height: 60px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.revolut-inner-container-div-desktop {
  position: relative;
  background-color: #F8F8F8;
  margin: 20px auto 20px auto;
  border-radius: 10px;
  border: 0.5px #F3F3F3 solid;
}

.revolut-inner-container-div-mobile {
  position: relative;
  width: 100%;
  background-color: #F8F8F8;
  margin: 10px auto 20px auto;
  border-radius: 10px;
  border: 0.5px #F3F3F3 solid;
}

.payment-button-desktop {
  height: 60px;
  width: 80%;
  border-radius: 100px;
  margin: auto auto 10px auto;
  display: block;
  border: none;
  color: white;
  transition: background 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Default shadow for depth */
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
}

.payment-button-mobile {
  height: 60px;
  width: 90%;
  border-radius: 100px;
  display: block;
  margin: auto;
  border: none;
  color: white;
  font-size: 16px;
  transition: background 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Default shadow for depth */

}

.payment-button-desktop:hover,
.payment-button-mobile:hover {
  cursor: pointer;
  background: #712fff; /* Hover background */
  box-shadow: 0px 6px 12px rgba(127, 67, 255, 0.3); /* Stronger shadow on hover */
}

.payment-button-desktop:active,
.payment-button-mobile:active {
  transform: scale(0.98); /* Slight "press" effect */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2); /* Pressed shadow */
}

.payment-button-desktop:focus,
.payment-button-mobile:focus {
  outline: none;
  box-shadow: 0px 0px 10px rgba(127, 67, 255, 0.5); /* Focused glow effect */
}

.revolut-payment-component__pay-button-div-desktop {
  top: 10px;
  position: relative;
  margin: auto;
  width: 100%;
}

.revolut-payment-component__pay-button-div-mobile {
  max-width: 90%;
  width: 375px;
  margin: auto;
  left: 50%;
  transform: translate(-50%, 0);
  position: fixed;
}

.revolut-payment-component__user-input-desktop {
  border: none;
  width: 95%;
  font-weight: 400;
  font-size: 14px;
  color: #737373;
  display: block;
  margin: 15px auto 0 auto;
  height: 45px;
  padding-left: 10px;
  border-radius: 5px;
}

.revolut-payment-component__user-input-mobile {
  border: none;
  font-weight: 400;
  width: 95%;
  font-size: 14px;
  display: block;
  margin: 15px auto 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  border-radius: 5px;
  color: #737373;
}

.revolut-payment-component__user-input-mobile::placeholder {
  color: rgb(115, 115, 115);
}

.revolut-payment-component__user-input-desktop::placeholder {
  color: rgb(115, 115, 115);
}

.card-details-input {
  font-size: 14px;
}

.cardbrand-logos {
  height: 20px;
  margin-right: 5px;
}

.revolut-target-container-desktop {
  display: block;
  overflow: hidden;
  background-color: #FFFFFF;
  margin: 15px auto 10px auto;
  height: 45px;
  width: 95%;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}

.revolut-target-container-mobile {
  margin: 15px auto 10px auto;
  height: 100%;
  max-height: 40px;
  overflow: hidden;
  width: 95%;
  background-color: #FFFFFF;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.revolut-logo {
  height: 16px
}

.revolut-payment-component__error-boundary {
  border: red solid 0.5px;
}

.revolut-payment-component__border-none {
  border: none;
}

.revolut-payment-component__error-message{
  font-size: 10px;
  color: red;
  font-family: Poppins, sans-serif;
  font-weight: 300;
  margin-left: 10px;
}

.revolut-apple-google-pay-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.revolut-apple-google-pay-title {
  position: absolute;
  top: -13px;
  background-color: white;
  width: 20%;
  text-align: center;
  color: #444444;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
}

.revolut-apple-google-pay-container-background {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  margin: 20px 0;
  padding-top: 30px;
  padding-bottom: 20px;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  width: 100%;
}

@media (max-width: 1110px) {
  .revolut-apple-google-pay-container {
    flex-direction: column;
  }
  .revolut-apple-google-pay-title {
    width: 40%;
    font-size: 14px;
  }
}