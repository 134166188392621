.m0 {
  margin: 0!important;
}

.mtm10 {
  margin-top: -10px;
}

.mt0 {
  margin-top: 0;
}

.mt4 {
  margin-top: 4px;
}

.mt5 {
  margin-top: 5px;
}

.mt6 {
  margin-top: 6px;
}

.mt8 {
  margin-top: 8px;
}

.mt10 {
  margin-top: 10px;
}

.mt12 {
  margin-top: 12px;
}

.mt15 {
  margin-top: 15px;
}

.mt16 {
  margin-top: 16px;
}

.mt18 {
  margin-top: 18px;
}

.mt20 {
  margin-top: 20px;
}

.mt24 {
  margin-top: 24px;
}

.mt25 {
  margin-top: 25px;
}

.mt28 {
  margin-top: 28px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt70 {
  margin-top: 70px;
}

.mt100 {
  margin-top: 100px;
}

.mt120 {
  margin-top: 120px;
}

.ml3 {
  margin-left: 3px
}

.ml4 {
  margin-left: 4px
}

.ml6 {
  margin-left: 6px
}

.ml10 {
  margin-left: 10px
}

.ml12 {
  margin-left: 12px
}

.ml16 {
  margin-left: 16px
}

.ml20 {
  margin-left: 20px
}

.ml30 {
  margin-left: 30px
}

.ml40 {
  margin-left: 40px
}

.ml60 {
  margin-left: 60px
}

.ml230 {
  margin-left: 230px
}

.mb0 {
  margin-bottom: 0px!important
}

.mb4 {
  margin-bottom: 4px
}

.mb5 {
  margin-bottom: 5px
}

.mb6 {
  margin-bottom: 6px
}

.mb8 {
  margin-bottom: 8px
}

.mb10 {
  margin-bottom: 10px
}

.mb15 {
  margin-bottom: 15px
}

.mb16 {
  margin-bottom: 16px
}

.mb20 {
  margin-bottom: 20px
}

.mb40 {
  margin-bottom: 40px
}

.mb70 {
  margin-bottom: 70px
}

.mb80 {
  margin-bottom: 80px
}

.mb100 {
  margin-bottom: 100px;
}

.mb120 {
  margin-bottom: 120px;
}

.mb150{
  margin-bottom: 150px;
}

.mb200 {
  margin-bottom: 200px
}

.mh10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m10 {
  margin: 10px;
}

.m20 {
  margin: 20px !important;
}

.m30 {
  margin: 30px;
}

.mr4 {
  margin-right: 4px
}

.mr6 {
  margin-right: 6px
}

.mr10 {
  margin-right: 10px
}

.mr15 {
  margin-right: 15px
}

.mr20 {
  margin-right: 20px
}

.mr30 {
  margin-right: 30px
}

.mr40 {
  margin-right: 40px
}

.m5{
  margin: 5px;
}

.mAuto {
  margin: auto;
}

.mlAuto {
  margin-left: auto;
}

.mrAuto {
  margin-right: auto;
}
