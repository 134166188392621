@import "./common/colors.less";

// .select-country-modal .ant-drawer-content{
//     border-radius: 30px;
// }

.select-country__body {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.select-country__title {
    color: @black;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-top: 20px;
}

.select-country__text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #6E6E6E;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 12px;
}

.select-country__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    width: 250px;
}

.select-country__button {
    cursor: pointer;
    border-radius: 100px;
    background: #F2F2F2;
    border-color: #F2F2F2;
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    color: @black;
    font-family: Poppins;
    font-size: 17.016px;
    font-style: normal;
    font-weight: 400;
    height: auto;
    &:hover {
        background: #E7E7E7 !important;
        border-color: #E7E7E7 !important;
        color: @black !important;
    }
    &.selected {
        background: #EDF8EE !important;
        border-color: #2EBB49 !important;
    }
}

@media (max-width: 700px) {
  
}
@primary-color: #2ebb49;