.progress-bar__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    width: 100%;
}

.progress-bar__title{
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.progress-bar__text-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.progress-bar__text-cell {
    margin: 0px;
    width: 115px;
    text-align: center;
    color: #9A9D9B;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
}

.progress-bar__line {
    height: 2px;
    width: 255px;
    background-color: #9A9D9B;
}
@media (max-width: 700px) {

    .progress-bar__text-cell {
        font-size: 11px;
    }
    .progress-bar__line {
        width: 70px;
    }
}
@media (max-width: 1109px) {

    .progress-bar__line {
        width: 90px;
    }
}
@primary-color: #2ebb49;