@import "./common/colors.less";

.code__wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.code__header,
.code__footer {
    width: 100%;
    height: 80px;
    padding: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.code__footer {
    position: fixed;
    bottom: 0;
    left: 0;
}

.main {
    flex: 1;
}

.inputStyle {
    width: 40px !important;
    height: 40px;
    margin: 6px 6px;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.inputStyle::placeholder {
    color: #D7D7D7;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;

  }

@media only screen and (max-width: 470px) {
    .margin-top--small .inputStyle {
        width: 2.2rem !important;
        height: 2.5rem;
        margin: 5px 5px;
        font-size: 20px;
    }

    .card > form > p {
        font-size: 1.2em !important;
    }
}

.margin-top--small {
    margin-top: 1rem;
    margin-bottom: -1rem;
}

.margin-top--small > div {
    flex-wrap: wrap;
}

.margin-top--small > div > div {
    margin-bottom: 1rem;
}

.margin-top--medium {
    margin-top: 1.5rem;
}

.margin-top--large {
    margin-top: 4rem;
}

.error {
    border: 1px solid red !important;
}

@media (max-width: 1109px) {
    .header {
        height: 56px;
        max-width: 375px;
    }
}

@primary-color: #2ebb49;