@import 'colors.less';

.tab {
  margin-left: 20px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  padding-top: 14px;
}

.tab-active {
  border-bottom: 2px solid @primary;
  color: @primary;
}