.payment-modal-container-desktop{
  width: 100%;
  border-radius: 20px;
  background: #FFF;
  padding: 0;
}

.payment-modal-container-mobile{
  width: 100%;
  border-radius: 20px;
  background: #FFF;
  padding: 0;
}

.payment-status-modal-taxzap-logo {
  position: relative;
  left: 2%;
  display: block;
  margin-left: auto;
  margin-right: auto;
};

.payment-modal-h3-text{
  color: var(--Others-Black, #111);
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.payment-modal-p-text{
  color: var(--Others-Black, #111);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.retry-payment-button{
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  padding: 30px 66px 30px 66px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  height: 40px;
  background-color: #7F43FF;
  border: #7F43FF;
  color: white;
  &:hover {
    background-color: #7F43FF !important;
    color: white !important;
    border: #7F43FF !important;
  }
}

.payment-animation-desktop{
  width: 300px;
  height: 300px;
  margin: 0 auto;
  padding: 0;
}

.payment-animation-mobile{
  width: 210px;
  height: 230px;
  margin: 0 auto;
}
