.new-questionnaire__shared-prompt-modal {
    .ant-modal-content {
        padding: 20px!important;
    }
}

.new-questionnaire__shared-prompt-modal__button-container {
    max-width: 345px;
    width: 100%;
    margin: auto;
}

.new-questionnaire__shared-delete-modal {   
    text-align: center;
    .ant-modal-content {
        border-radius: 20px;
        padding: 20px!important;
    }
}

.new-questionnaire__shared-delete-modal__button-container {
    max-width: 345px;
    width: 100%;
    margin: 40px auto 0px;
}

.new-questionnaire__shared-delete-modal__title {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.18px;
}

.new-questionnaire__shared-delete-modal__text {
    color: #707070;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
}

.new-questionnaire__explainer-modal__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 440px;
    padding: 0px 20px 25px;
}

.new-questionnaire__explainer-modal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.new-questionnaire__explainer-modal__title {
    color: #31333F;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.1px;
}

.new-questionnaire__explainer-modal__sub-title {
    color: #7F43FF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.1px;
}

.new-questionnaire__explainer-modal__text {
    color: #767682;
    font-family: Poppins;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.1px;
}

.new-questionnaire__explainer-modal__header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    height: 30px;
}

.new-questionnaire__explainer-modal__header__text {
    color: #767682;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.1px;
}

.new-questionnaire__explainer-modal__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
}

.new-questionnaire__explainer-modal__header__close-button {
    color: #0666EB;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.1px;
    cursor: pointer;
    padding: 4px;
}

.new-questionnaire__explainer-modal__dots {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-bottom: 16px;
}

.new-questionnaire__explainer-modal__dot {
    width: 6px;
    height: 6px;
    background-color: #D9D9D9;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.new-questionnaire__explainer-modal__dot.active {
    background-color: #31333F;
}

.video-crop-container {
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
    height: 190px;
}

.video-crop-container video {
    display: block;
    position: relative;
    top: 5px;
    width: 100%;
}

@media (max-width: 1109px) {
    .new-questionnaire__explainer-modal__container {
        min-height: 400px;
        padding: 0px;
    }
}