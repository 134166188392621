@import "../common/colors.less";
@import "./shared.less";

.submit-page {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100%;
  max-width: 335px;
  margin: auto;
  margin-bottom: 80px;
}

.submit-page__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.submit-page__success-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.submit-page__title {
  color: var(--black, #000);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  padding: 30px 0 10px;
  margin-bottom: 10px;
}

.submit-page__submit-explainer {
  color: var(--black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 0 auto 10px auto;
  max-width: 500px;
}

.submit-page__submit-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.submit-page__bank-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 20px;
  width: 100%;
}

.submit-page__bank-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 25px;
  width: 50dvw;
  max-width: 1000px;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 3px 10px 0px rgba(100, 133, 249, 0.1), 0px -4px 10px 0px rgba(100, 133, 249, 0.1);
}

.submit-page__bank-details__learn-more {
  color: @blue;
  cursor: pointer;
  text-decoration: underline;
}

.submit-page__desktop-form-back {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-top: 20px;
  cursor: pointer;
  width: 100%;
}

.submit-page__desktop-form-back-text {
  color: #555;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  margin-left: 4px;
}

.submit-page__tax-summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.submit-page__summary-tax-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 25px;
  max-width: 1000px;
}


@media (max-width: 1109px) {
  .submit-page__bank-details-container {
    padding: 15px;
    max-width: 95%;
    width: auto;
  }
}
@primary-color: #2ebb49;