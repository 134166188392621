@import "./common/colors.less";
.uk-review-text{
    color: var(--blue, #2752F1);
    font-style: normal;
    font-weight: 500;
    margin-left: 3px;
    font-size: 12px;
    margin-top: 3px;
}
.customers-saying-card {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 20px;
    background: @white;
    // margin-right: 20px ;
    padding: 20px 0px 0px 0px;
    gap: 10px;
    border-radius: 20px 20px 20px 20px;
    border: 2px solid var(--background, #F7F7F7);
    padding:20px;
    min-height: 323px;
    max-width: 340px;
}

.customers-saying-card-new {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 20px;
    background: @grey9;
    padding: 20px 0px 0px 0px;
    gap: 10px;
    border-radius: 20px 20px 20px 20px;
    border: 2px solid var(--background, #F7F7F7);
    padding:20px;
    min-height: 323px;
    max-width: 340px;
}

.uk-review-slogan{
    color: #0C0B0A;
    font-size: 47.461px;
    font-style: normal;
    font-weight: 800;
    line-height: 60px;
}
.swiper-navigation-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: @white;
}

.review-content-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    height: inherit;
}

.review-content {
    color: @black;
    padding: 0px 20px;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: inherit;
    margin-bottom: 0px !important;
}

.review-author {
    align-items: center;
}

.review-author-icon {
    width: 36px;
    height: 36px;
}

.review-author-text {
    color: @black;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 6px;
}
@media screen and (max-width: 500px) {
    .uk-review-slogan{
        font-size: 26px;
        font-style: normal;
        font-weight: 800;
        line-height: 34px;
    }
    .review-content{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    .customers-saying-card{
        padding: 15px;
    }
}