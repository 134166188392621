@import "./common/colors.less";

.header-wrapper {
    position: fixed;
    top: 0;
    background-color: @white;
    z-index: 100;
    width: 100%;
}

.uk-menu-item {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 28px;
}
.uk-menu-wrapper{
    border-radius: 100px;
    gap: 10px;
    flex: none;
    background-color: #f7f7f7;
    height: 48px;
}
.uk-button {
    font-family: "Poppins", sans-serif;
}

.uk-button-new {
    font-family: "Poppins", sans-serif;
    height: 48px;
}

.uk-link-button {
    font-family: "Poppins", sans-serif;
    color: @secondary;

    &:hover {
        color: @secondaryDark;
    }
}

.uk-link-button-label {
    text-decoration: underline;
    color: @secondary;
    &:hover {
        color: @secondaryDark;
    }
}

.uk-link-button-label-new {
    color: @black;
}

.uk-flag {
    border-radius: 100%;
}

.profile-mobile-header-wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: @white;
    z-index: 3;
    border-bottom: 1px solid @grey2;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1100px;
    height: 80px;
    padding: 20px 16px;
    background: none;
    z-index: 3;
}

.tab-header {
    justify-content: space-between;
    padding: 0px;
}

.header-burger-menu__logo {
    cursor: pointer;
    height: 26px;
    width: 138px;
    margin-left: 16px;
}

.tab-header-title {
    color: @grey8;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    margin: 0;
}

.ant-menu-horizontal >.ant-menu-submenu::after{
    content: none !important;
}

.ant-menu-submenu-title {
    display: flex !important;
}

@media (max-width: 1109px) {
    .profile-mobile-header-wrapper {
        height: 56px;
    }
}
@media (max-width: 1199px) {
    .uk-menu-wrapper {
        background-color: @white;
    }
}