.section-done-progress {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    filter: drop-shadow(0px 0.4px 0.4px rgba(0, 0, 0, 0.08));

    svg {
        filter: url(#inner-shadow);
    }

    .section-dot {
        fill: #dbdbdb;
        width: 6px;
        height: 6px;
    }

    .badge-icon {
        display: block;
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 48px;
        height: 48px;
    }

    .progress-text {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        .progress-count {
            color: #767682;
            text-align: center;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.1px;
        }

        .progress-next {
            color: #767682;
            text-align: center;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.1px;
        }
    }
}
@primary-color: #2ebb49;