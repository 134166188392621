@import './colors.less';


.button-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}

.inverted-button {
  border: 1px solid white;
  color: white;
  background-color: transparent;
}

.inverted-button:hover {
  color: @primary;
  border: 1px solid white;
  background-color: white;
}

.primary-button {
  color: @primary;
  border-color: @primary;
  background-color: transparent;
}

.secondary-button {
  color: white;
  border-color: @secondary;
  background-color: @secondary;
}

.primary-button:hover {
  background-color: @primary;
  color: white;
  border-color: @primary;
}

.default-button {
  border-color: white;
  color: @primary;
}

.default-button:hover {
  background-color: @primary;
  color: white;
}

.danger-button {
  color: @red;
  border-color: @red;
}

.danger-button:hover {
  background-color: @red;
  border-color: @red;
  color: white;
}

.custom-button-icon {
  margin-right: 8px;
  font-size: 22px;
  vertical-align: middle;
  padding-bottom: 2px;
}

.danger-button-filled {
  background-color: @red;
  color: white;
}

.danger-button-filled:hover, .danger-button-filled:focus {
  background-color: transparent;
  border-color: @red;
}

.green-button-filled {
  background-color: @brand;
  color: white;
  border-color: @brand;
}

.green-button-filled:hover, .green-button-filled:focus {
  background-color: transparent;
  border-color: @brand;
  color: @brand;
  box-shadow: none;
}

.primary-button-filled {
  background-color: @primary;
  color: white;
  border-color: @primary;
}

.primary-button-filled:hover, .primary-button-filled:focus {
  background-color: transparent;
  border-color: @primary;
  color: @primary;
}

.primary-icon-pressed {
  color: @primary;
  background-color: @brandFivePercent;
  border-radius: 4px;
}
