@import "../common/colors.less";

.document-parse-prompt-close {
    color: @blue;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 22px;
    letter-spacing: -0.1px;
    cursor: pointer;
}

.document-parse-prompt-drawer .ant-drawer-body,
.document-parse-prompt-drawer .ant-drawer-content,
.document-parse-prompt-drawer .ant-drawer-header {
    background-color: @grey1 !important;
}

.document-parse-prompt-continue, .document-parse-prompt-continue-desktop, .document-parse-prompt-continue-grey-desktop, .document-parse-prompt-continue-grey {
    cursor: pointer;
    margin: 10px 0;
    border-radius: 100px;
    background: @secondary;
    color: @white;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    border: none;
    display: flex;
    width: 90%;
    height: 44px;
    padding: 8px 11px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #8147FF, 0px 1px 3px 0px #9E70FF, 0px -2.4px 0px 0px #8852FB inset;
}

.document-parse-prompt-continue-grey-desktop, .document-parse-prompt-continue-grey {
    color: @black;
    background: #F5F6F8;
    box-shadow: 0px 0px 0px 1px @secondaryLight, 0px 1px 3px 0px rgba(143, 143, 143, 0.20), 0px -2.4px 0px 0px rgba(62, 62, 62, 0.04) inset;
}

.document-parse-prompt-link {
    color: @blue;
    font-family: Poppins;
    font-size: 16px;
    text-decoration-line: underline;
}

.document-parse-prompt-continue-desktop {
    width: 335px;
    margin-top: 40px;
}

.document-parse-prompt-continue-grey-desktop {
    margin-top: 20px;
    width: 335px;
}

.document-parse-prompt-footer {
   display: flex;
   justify-content: space-between;
}

.document-parse-prompt-bullet-point {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-bottom: 20px;
}

.document-parse-prompt__array-add-button {
    cursor: pointer;
    color: @white;
    font-family: Poppins;
    font-size: 14px;
    border-radius: 8px;
    background: #7F43FF;
    box-shadow: 0px 0px 0px 1px #8147FF, 0px 1px 3px 0px #9E70FF, 0px -2.4px 0px 0px #8852FB inset;
    display: flex;
    width: 108px;
    padding: 8px 11px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    &:hover {
        box-shadow: 0px 0px 7px #7f43ff72;
    }
}

.document-parse-prompt__array-add-button-light {
    cursor: pointer;
    width: 144px;
    margin: 0 auto;
    padding: 6px 15px;
    border-radius: 8px;
    color: #7F43FF;
    font-family: Poppins;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.084px;
    border-radius: 8px;
    border: 1px solid #7F43FF;
    background: #EFE8FF;
    box-shadow: 0px 0px 0px 1px #E5D9FF, 0px 1px 3px 0px rgba(127, 67, 255, 0.20), 0px -2.4px 0px 0px rgba(42, 0, 130, 0.04) inset;
    
    &:hover {
        box-shadow: 0px 0px 7px #7f43ff72;
    }
}
.document-parse-prompt__array-box{
    border-radius: 10px;
    border: 1px dashed #7F43FF;
    background: #EFE8FF;
    width: 4;
    height: 184px;
    flex-shrink: 0;
    padding: 20px;
    justify-content: center;
    ;display: flex; 
    flex-direction: column;
    gap: 20px;
}

.document-parse-prompt__array-add-button-grey {
    display: flex;
    width: 108px;
    padding: 8px 11px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #F5F6F8;
    cursor: pointer;
    box-shadow: 0px 0px 0px 1px #EBEBEB, 0px 1px 3px 0px rgba(143, 143, 143, 0.20), 0px -2.4px 0px 0px rgba(62, 62, 62, 0.04) inset;
}
.document-parse-prompt-container  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 20px;
  }
@primary-color: #2ebb49;