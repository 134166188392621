@import "./common/colors.less";

.install-modal {
    .ant-modal-content {
        padding: 0px !important;
        .ant-modal-body {
            padding: 0px;
        }
    }
}

.install-modal__content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.install-modal__title {
    color: @black;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.408px;
}

.install-modal__text {
    color: #444;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.install-modal__footer {
    display: flex;
    align-items: center;
}

.install-modal__close-button {
    border-top: 1px solid #F7F7F7;
    border-right: 0.5px solid #F7F7F7;
    width: 50%;
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: @brand;
    text-align: center;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.408px;
}

.install-modal__install-button {
    border-top: 1px solid #F7F7F7;
    border-left: 0.5px solid #F7F7F7;
    width: 50%;
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: @brand;
    text-align: center;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.408px;
}

.install-modal__got-it-button {
    border-top: 1px solid #F7F7F7;
    width: 100%;
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: @brand;
    text-align: center;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.408px;
}

.install-modal__instructions {
    margin: 0px;
    font-size: 14px;
}

.install-modal__instructions .icon {
    vertical-align: bottom;
    margin-right: 8px;
}

@primary-color: #2ebb49;