@import "../common/colors.less";

.return-status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    padding: 0 25px;
}

.return-status__separator {
    width: 105px;
    height: 2px;
    background: #C4C4C4;
    margin: 29px 0px;
    border-radius: 2px;
}

.return-status__separator-active {
    width: 105px;
    height: 2px;
    background: #2EBB49;
    margin: 29px 0px;
    border-radius: 2px;
}
.return-status__separator-error {
    width: 105px;
    height: 2px;
    background: #ff0000;
    margin: 29px 0px;
    border-radius: 2px;
}

.return-status__details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
}

.return-status__title {
    margin-top: 6px;
    color: @grey5;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: max-content;
}

.return-status__title-active {
    margin-top: 6px;
    color: @grey8;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: max-content;
}

.return-status__timing {
    color: @grey5;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: max-content;
}

@media (max-width: 1109px) {
    .return-status {
        margin-top: 24px;
        width: 375px;
    }
    .return-status__details {
        width: 48px;
    }
    .return-status__details-icon {
        width: 48px;
    }

    .return-status__separator,
    .return-status__separator-active,
    .return-status__separator-error {
        margin: 23px 0px;
    }
}
@primary-color: #2ebb49;