@import "../common/colors.less";

.select-bank__container {
    width: 100%;    
}

.select-bank__list-container {
    max-height: 90vh;
    min-height: 90vh;
    width: 100%;    
    overflow-y: auto;    
}

.select-bank__continue-button {
    box-sizing: border-box;
    display: flex;
    max-width: 335px;
    width: 100%;
    height: 64px;
    padding: 22px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid #FFF500;
    background: #000;
    cursor: pointer;
    transition: box-shadow 0.1s ease-in-out;
}

.select-bank__connect-success__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    height: 100%;
}

.select-bank__connect-success__title {
    color: #121212;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;

}

.select-bank__success-animation {
    height: 80%;
    width: 80%;
}

.select-bank__continue-button-text {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}


.select-bank__sticky-header {
    position: sticky;
    top: 0;
    background-color: #FFF;
    z-index: 1;
    padding: 10px;
   
}

.select-bank__search .ant-input {
    font-family: Poppins !important;
    font-size: 16px !important;
}

.select-bank__list-item {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: flex-start !important;
    border-radius: 16px;
    background: #F7F7F7;
    margin: 10px 0;
    padding: 12px 20px !important;
    cursor: pointer;
}

.select-bank__list-item.selected {
    border: 2px solid @brand;
    border-block-end: 2px solid @brand !important;
    border-color: @brand;
}


.select-bank__list-item__title {
    margin-left: 16px;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    max-width: 450px;
}

.select-bank__list-item__logo {
    width: 35px;
    height: 35px;
}

.select-bank__list-item__logo.selected {
    border: 2px solid @brand;
    border-block-end: 2px solid @brand !important;
    border-color: @brand;
}

@media (max-width: 1109px) {

    .select-bank__list-item__title {
        margin-left: 6px;
        font-size: 16px;
        max-width: 250px;
    }

    .select-bank__list-item__logo {
        width: 30px;
        height: 30px;
    }
    
}

@primary-color: #2ebb49;