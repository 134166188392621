@import "./common/colors.less";
    .marketing-sub-slogan {
       font-size: 80px;
       font-weight: 800;
       line-height: 40px;
       font-family: Poppins 
    }
    .uk-marketing-video {
        border-radius: 10px;
        object-fit: cover;
        width: 406px;
    }

  @media (max-width: 1200px) {
    .marketing-sub-slogan {
      font-size: 40px;
    }
  }
  
  @media (max-width: 1415px) {
    .marketing-sub-slogan {
        font-size: 42px;
        font-weight: 800;
        line-height: 40px
    }
  }

