@import "../common/colors.less";

.payment-details__form {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 20px 60px;
  margin-bottom: 0;
}

.payment-details__bank-details__button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 60px;
  margin-top: 24px;
}


.payment-details__bank-details__info-button {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.payment-details__form__row {
  display: flex;
  width: 100%;
  justify-content: center;
}

.payment-details__input,
.ant-input-number-input-wrap input {
  height: 48px;
  width: 100%;
}

.payment-details__input-text,
.ant-picker .ant-picker-input>input[disabled],
.ant-picker .ant-picker-input>input,
.ant-input-number .ant-input-number-input {
  color: @black !important;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.payment-details__input-label .ant-form-item-label>label {
  color: @grey7;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap !important;
}

.payment-details__form__container {
  width: 100%
}

.payment-details__desktop-form__back-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  margin-top: 20px;
  cursor: pointer;
  width: 90%;
}

.payment-details__desktop-form__back-text {
  color: #555;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
  margin-left: 4px;
}

.payment-details__bank-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 25px;
  width: 50dvw;
  max-width: 1000px;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 3px 10px 0px rgba(100, 133, 249, 0.1), 0px -4px 10px 0px rgba(100, 133, 249, 0.1);
}

@media (min-width: 1100px) {
  .payment-details__desktop-form-button {
    width: 48%;
  } 
}

@media (max-width: 1100px) {
  .payment-details__form {
    grid-template-columns: 1fr;
    gap: 0px;
    margin-top: 12px;
  }

  .payment-details__bank-details__button-container {
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
  }

  .payment-details__bank-details-container {
    padding: 15px;
    max-width: 95%;
    width: auto;
  }
}
@primary-color: #2ebb49;