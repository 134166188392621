@import "../common/colors.less";

@keyframes moveLeftRight {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(2px);
    }
  }
.profile__content .success{
    margin: auto
}

.arrow-container {
    margin-top: 1px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: moveLeftRight 1s linear infinite;
}

.idpal__content{
    display: grid;
    justify-content: center;
    height: 80vh;
}

.idpal__content-description{
    width: 690px;
    height: 657px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF;
    padding: 20px;
}

.idpal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.idpal__content-image {
    display: flex;
    width: 240px;
    height: 155px;
    margin: auto;
    margin-bottom: 36px;
}

.idpal__content-title {
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.success__content-title {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
}
.idpal__content-text {
    color: @grey8;
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.idpal__content-heading {
    color: #540087;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: flex-end;
    border-radius: 100px;
    background: #F3DFFF;
    display: flex;
    padding: 5px 10px;
    gap: 6px;
    align-items: center;
}
.idpal__content-terms-text{
    color: var(--Grey-7, var(--secondary, #555));
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.error__icon{
    width: 90px;
    height: 90px;
    display: flex;
    margin: auto;
    margin-top: 70px;
}

@media (max-width: 1109px) {
    .idpal__header {
        max-width: 345px;
        flex-direction: column;
    }
}

@primary-color: #2ebb49;