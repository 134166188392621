@import "./common/colors.less";

.builder__toggle-question {
    display: flex;
    flex-direction: column;

    .builder__form-question {
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: @grey8;
    }

    .builder__toggle-question__switch {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .ant-switch {
            background-color: @grey4;
        }

        .ant-switch-checked {
            background-color: @primary;
        }

        .ant-switch-checked .ant-switch-inner {
            font-family: Poppins, sans-serif;
            color: @white;
        }

        .ant-switch-inner {
            font-size: 14px;
            color: @black
        }
    }

    .builder__form-error {
        font-size: 14px;
        color: @red;
        margin-top: 5px;
    }
}

@primary-color: #2ebb49;