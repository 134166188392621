.payment-screen-container-desktop {
  position: relative;
  left: -5%;
  min-width: 800px;
  margin: 30px auto 0 auto;
  height: 100%;
}

.payment-screen-container-mobile {
  max-width: 100vw;
  position: relative;
  width: 400px;
}

.no-payment-continue-button-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.no-payment-continue-button-desktop {
  height: 60px;
  width: 70%;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  border-radius: 100px;
  background: #7F43FF;
  margin: 20px auto 10px auto;
  display: block;
  border: none;
  color: white;
}

.no-payment-continue-button-mobile {
  height: 50px;
  width: 70%;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  border-radius: 100px;
  background: #7F43FF;
  margin: 20px auto 10px auto;
  display: block;
  border: none;
  color: white;
}