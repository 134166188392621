@import "../common/colors.less";
.dashboard__subtitle {
    color: #707070;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 10px;
}

.dashboard__wrapper-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.dashboard-navbar__calendar {
    display: flex;
    align-items: center;
    padding: 12px;
    align-items: center;
    border-radius: 10px;
    border: 1px solid @grey4;
    justify-content: space-between;
    gap: 7px;
}

.client-progress__text-link{
    color: @blue;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    text-decoration-line: underline;
    margin-top: 10px;
}

.client-progress__content-desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.client-progress__content-mobile {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.client-progress__text{
    color: @textHover;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 10px;
}
.ready-for-review__panel {
    .ant-collapse, .ant-collapse-item, .ant-collapse-header {
        padding: 10px 0 !important;
    }
    .ant-collapse-item, .ant-collapse-header {
        display: flex;
        flex-direction: row-reverse;
        padding: 0;
    }
    .ant-collapse, .ant-collapse-content , .ant-collapse-content-box {
        padding: 0 !important;
    }
}
.dashboard__layout {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    background: @white;
    box-shadow: 0px -1.818px 9.375px 0px rgba(166, 185, 255, 0.10), 0px 1.818px 9.375px 0px rgba(166, 185, 255, 0.10);
    padding-top: 0;
}

.dashboard__wrapper {
    margin: 20px 0px 20px 0px;
    display: flex;
    flex-direction: column;
}

.dashboard__content  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 115px;
    max-width: 207px;
}

.dashboard__button-purple,
.dashboard__button-light-purple,
.dashboard__button-grey {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    cursor: pointer;
    transition: box-shadow 0.1s ease-in-out;
    height: 44px;
    margin-top: 10px;
}

/* Specific Button Background Colors */
.dashboard__button-purple {
    background: @secondary;
}

.dashboard__button-light-purple {
    background: @secondaryLight;
}

.dashboard__button-grey {
    background: @grey0;
}

/* Active/Pressed Effects */
.dashboard__button-purple:active,
.dashboard__button-light-purple:active,
.dashboard__button-grey:active {
    transform: scale(0.98); /* Slight "press" effect */
    box-shadow: 0px 0px 7px #dedede72;
}

.dashboard__title, .dashboard__title-hmrc {
    color: @black;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    display: flex;
    gap: 17px;
    align-items: center;
}

.dashboard__title-hmrc {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
}

.dashboard__calendar {
    display: flex;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid @grey4;
    gap: 7px;
}
.dashboard__image-desktop {
    width: 60px;
}

.dashboard__button-text, .dashboard__button-text-black {
    color: @white;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.dashboard__button-text-black {
    color: @black;
}

.dashboard__layout {
    .profile__content-body {
        .ant-steps-item-content {
            width: 100%  !important;
        }
    }
}
.dashboard__layout {
    .profile__content-body {
        .ant-steps.ant-steps-vertical{
            gap: 20px;
        }
    }
}

.dashboard__layout {
    .profile__content-body {
        .ant-steps-item-title{
            color: @black  !important;
            font-family: Poppins  !important;
            font-size: 16px  !important;
            font-style: normal  !important;
            font-weight: 600  !important;
            line-height: normal !important;
            display: flex  !important;
            gap: 17px  !important;
            width: 207px !important;
            height: 60px;
            justify-content: center;
        }
    }
}

.dashboard__layout {
    .profile__content-body {
        .ant-steps-item-description {
            color: @textHover !important;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
            text-align: center;
        }
    }
}

.dashboard__layout {
    .profile__content-body {
        .ant-steps {
            gap: 30px
        }
    }
}

.dashboard__layout {
    .profile__content-body {
        .ant-steps-label-vertical .ant-steps-item-tail {
            width: 270px;
        }
    }
}

.dashboard__layout {
    .profile__content-body {
        .ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
            padding: 20px 15px;
        }
    }
}

.dashboard__layout {
    .profile__content-body {
        .ant-steps.ant-steps-vertical >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
            width: 1.5px
        }
    }
}

.dashboard__layout {
    .profile__content-body {
        .ant-steps.ant-steps-horizontal >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
            height: 2px
        }
    }
}

.dashboard__layout {
    .profile__content-body {
        .ant-steps-item-icon {
            padding-left: 30px;
        }
    }
}

.dashboard__stepper {
    /* Change connector color for completed steps */
    .ant-steps-item-finish .ant-steps-item-tail::after {
        background-color: @brand !important;  /* Change to your desired color */
    }
}

.dashboard__wrapper {
    display: flex;
    flex-direction: row;
 }

@media (max-width: 1109px) {
   .dashboard__layout {
        margin-top: 20px;
        margin-bottom: 100px;
    }

    .dashboard__wrapper {
       flex-direction: column-reverse;
    }

    .dashboard__image-mobile {
        width: 32px;
    }

    .dashboard__content {
        min-height: auto;
        max-width: 100%;;
    }

    .dashboard__title {
        font-size: 20px;
        text-align: center;
    }

    .dashboard__layout {
        .profile__content-body {
            .ant-steps-item-container{
                display: flex;
                justify-content: space-evenly;
            }
        }
    }

    .dashboard__layout {
        .profile__content-body {
            .ant-steps.ant-steps-label-vertical .ant-steps-item-content {
                align-self: stretch;
                display: flex;
                align-items: center;
            }
        }
    }

    .dashboard__layout {
        .profile__content-body {
            .ant-steps-item-title {
                padding-bottom: 8px;
                height: auto;
                justify-content: flex-start;
            }
        }
    }

    .dashboard__layout {
        .profile__content-body {
            .ant-steps-item-description {
                text-align: left;
            }
        }
    }

    .dashboard__layout {
        .profile__content-body {
            .ant-steps-item-icon {
                padding-left: 0px;
            }
        }
    }

}

@primary-color: #2ebb49;