@import "../common/colors.less";

.content {
    // overflow: auto;
    margin: 0 auto;
    width: 100%;
}

.profile__layout {
    min-height: 100dvh;
    min-width: 100dvw;
    background: @white;
}

.profile__page {
    width: 1480px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.profile__menu-wrapper {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-right: 1px solid @grey0;
}

.profile__menu-wrapper .ant-layout-sider-children {
    margin-top: 0;
    padding-top: 0;
}

.profile__content {
    background: @white;
    width: 100%;
    height: 100%;
    padding: 30px;
    position: relative;
    // overflow: scroll;
}

.profile__client {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 20px;
    border-top: 1px solid @grey0;
}

.profile__client-name {
    color: @black;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.profile__client-name-email {
    color: @grey6;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.profile__menu {
    height: 100%;
    border-right: 0;
    background-color: @grey7;
}

.profile__menu-item {
    height: 60px !important;
}

.profile__submenu .ant-menu-submenu-title {
    height: 60px !important;
    line-height: 60px !important;
}

.profile__menu-group-title {
    color: @grey7;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.08px;
}

.profile__submenu .ant-menu-sub {
    background: none !important;
}

.profile__menu-item.ant-menu-item {
    margin: 15px 0;
    color: @grey5;
    font-family: "Poppins";
    border-radius: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.08px;
}

.profile__menu-item.ant-menu-item-selected {
    color: @brand !important;
    background-color: transparent;
}

.profile__menu-item.ant-menu-item:hover {
    color: @textHover !important;
    background-color: @grey0 !important;
}

.profile__submenu.ant-menu-submenu .ant-menu-submenu-title {
    color: @grey7;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.36px;
}
.profile__submenu.ant-menu-submenu:hover .ant-menu-submenu-title {
    background-color: @brandLightTenPercent;
}
.profile__submenu.ant-menu-submenu-selected .ant-menu-submenu-title {
    color: @grey1 !important;
    background-color: @brand !important;
}

.profile__submenu.ant-menu-item {
    color: @grey6;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 149.925%;
}

.profile__submenu.ant-menu-item-selected {
    color: @secondary !important;
    background-color: @secondaryLight !important;
}
.profile__submenu.ant-menu-item-selected:hover {
    color: @secondary !important;
    background-color: @selectedHover !important;
}

.profile__content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    padding: 20px 30px;
    background-color: @grey1;
    border-radius: 10px;

}

.profile__content-heading {
    color: @black;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.48px;
}

.profile__refer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 36px;
    border-radius: 10px;
    background: @brandLightFivePercent;
    color: @brand;
    cursor: pointer;
}

.profile__refer-text {
    margin-left: 12px;
    color: @brand;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.32px;
    text-align: center;
}

.profile__notification {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 24px;
    cursor: pointer;
}

.profile__content-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding-bottom: 50px;
    border-radius: 20px;
}

.profile__content-title {
    margin-top: 30px;
    color: @grey8;
    text-align: center;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.profile__content-text {
    margin-top: 30px;
    color: @grey7;
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.profile__link-button {
    padding: 12px 0px;
    cursor: pointer;
    color: @blue;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
}

.profile__new-submission-button {
    margin: 30px auto 0;
    // margin-top: 30px;
    display: block;
    width: 335px;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    background: @brand;
    color: @white !important;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    &:hover {
        background: @brandDark;
        border: none;
        box-shadow: none;
    }
}

.profile__select-tab-button {
    margin: 30px auto 0;
    // display: block;
    width: 335px;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    background: @brand;
    color: @white !important;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    &:hover {
        background: @brandDark;
        border: none;
        box-shadow: none;
    }
}

.profile__secondary-button {
    width: 335px;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    padding: 0;
    background: @white;
    color: @brand !important;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    border: 1px solid @brand;
    &:hover {
        background: @brandSuperLight;
        box-shadow: none;
    }
}

.profile__primary-button {
    width: 335px;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    background: @brand;
    color: @white !important;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    &:hover {
        background: @brandDark;
        border: none;
        box-shadow: none;
    }
}

.profile__save-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto 0;
    width: 335px;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    background: @brand;
    color: @white !important;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
}

.profile__status-content {
    width: 100%;
}

.profile__sub-content {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile__content-sub-title {
    color: @grey8;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.profile__content-sub-text {
    margin-top: 20px;
    color: @grey7;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.profile__content-finish-title {
    color: @grey8;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.profile__content-finish-text {
    margin-top: 16px;
    color: @grey7;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.profile-burger-name {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-burger__refer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    padding: 9px;
    cursor: pointer;
}

.profile-burger__notification {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    padding: 10px;
    cursor: pointer;
}

.profile-burger__refer-icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background: @brand;
}

.profile__content-email-text {
    text-decoration: none;
    color: @brand !important;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.08px;
}

.profile__content-email-text-grey {
    text-decoration: none;
    color: @grey7 !important;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.08px;
}

.profile__content-email-text-grey {
    text-decoration: none;
    color: @grey7 !important;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.08px;
}

.profile__content-link-text {
    color: @grey7 !important;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline !important;
}

.profile__notifications-wrapper {
    position: absolute;
    width: 320px;
    min-height: 828px;
    padding: 20px;
    background-color: @white;
    border: 1px solid @grey2;
    border-radius: 10px;
    top: 31px;
    right: 31px;
    transform: translateY(-200%);
    transition: transform 0.3s ease, opacity 0.3s ease;

    &.active {
        transform: translateY(0);
    }
}

.profile__notifications-mobile-wrapper {
    position: fixed;
    width: 345px;
    padding: 20px;
    z-index: 7;
    background-color: @white;
    border-radius: 20px;
    top: 9px;
    margin: 0 15px;
    transform: translateY(-200%);
    transition: transform 0.3s ease, opacity 0.3s ease;

    &.active {
        transform: translateY(0);
    }
}

.profile__notifications-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.profile__notifications-content {
    margin-top: 30px;
    max-height: calc(73dvh);
    overflow-y: auto;
}

@media (min-height: 940px) {
    .profile__notifications-content {
        max-height: 710px;
    }
}

.profile__no-notifications-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.profile__no-notifications-header {
    color: @grey8;
    text-align: center;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: -0.4px;
}

.profile__no-notifications-text {
    color: @grey7;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.profile__notification-title {
    color: @grey8;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
}

.profile__notification-text {
    margin-top: 6px;
    color: @grey7;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.profile__notification-date {
    margin-top: 2px;
    color: @grey5;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.profile__past-returns-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.profile__past-returns-text {
    font-family: "Poppins";
    color: @grey7;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.profile__more-text {
    color: @brand;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.profile-questionnaire-wrapper {
    position: relative;
    margin-top: 20px;
}

.img-row{
    gap: 70px ;
    padding-top: 20px;
}
.profile__status-content-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.profile__status-content-questionnaire {
    width: 375px;
    border-left: 1px solid @grey4;
}

.profile__status-content-main {
    max-width: 690px;
}

.profile__content-big-title {
    color: @black;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.48px;
}

.profile__content-warning-text {
    color: @red;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
    padding: 0 20px;
}

.profile__content-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    background-color: @grey1;
    margin-top: 30px;
    border-radius: 8px;
}

.profile__refund-content-title {
    color: @black;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.16px;
}

.profile__refund-content-amount {
    color: @brand;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.16px;
}

@media (max-width: 1109px) {
    .profile__layout {
        background: @white;
    }

    .profile__page {
        width: 375px;
    }

    .profile__client-name {
        font-size: 16px;
        line-height: 18px;
    }

    .profile__content {
        height: 100%;
        display: flex;
        justify-content: center;
        padding: 0;
        overflow: visible;
    }

    .profile__content-header {
        margin-top: 0px;
        max-width: 375px;
        justify-content: center;
    }

    .profile__content-heading {
        font-size: 18px;
        font-weight: 600;
        margin-top: 5px;
    }

    .profile__content-body {
        margin-top: 0px;
        margin-bottom: 60px;
        // width: 375px;
        width: 100%;
        height: max-content;
        padding: 0px;
    }
    .accountOption_list-p{
        padding: 1rem 0;
        margin-bottom: 60px;
    }

    .profile__status-content {
        margin-bottom: 80px;
        // padding: 20px;
    }

    .profile__content-title {
        font-size: 18px;
        margin-top: 12px;
    }

    .profile__sub-content {
        width: 375px;
        margin: 0 auto;
    }

    .profile__select-tab-button {
        margin: 0px;
    }

    .profile__content-finish-title {
        font-size: 16px;
    }

    .profile__content-finish-text {
        font-size: 15px;
    }

    .profile__no-notifications-wrapper {
        margin-top: 0px;
    }

    .profile__notifications-content {
        margin-top: 0px;
    }
    .img-row{
        gap: 10px ;
        padding-top: 20px;
        margin-left: 24%;
    }
    .profile__content-text{
        font-size: 14px;
    }
}

@primary-color: #2ebb49;